import React from 'react';
import { useState } from 'react';
import Logo from '../../../images/endwelllcare-logo.png';
import LoginImg from '../../../images/sign-up.jpeg';
import { useNavigate } from "react-router-dom";
import TeamLogin from "../../../Service/Team/Login/LoginService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../Loader/Loading';
const OtpPassword = (props)=> {
    
    const navigate = useNavigate();
    const [email,setEmail] = useState(props.email)    
    const [otp,setOtp] = useState("")    
    const [password,setPassword] = useState("")    
    const [password_confirm,setPassword_confirm] = useState("")  
    const [loading,setLoading] = useState(false)  
    const sumbitOtpVerify = async (e)=>{
        e.preventDefault();
        if(validate()){
            let post = {email:email,otp:otp,password:password,password_confirm:password_confirm};
            const apicall = await TeamLogin.OtpVerify(post)
            if (apicall.state === true) {
                apicall.result.json().then((resp1) => {
                    if (resp1.status == 200) {
                        navigate('/')
                    }else{
                        toast(resp1.message)
                    }
                });
            } else {
                apicall.result.json().then((resp1) => {
                    if (resp1.status === 400) {
                        toast(resp1.error.password_confirm[0])
                    } 
                });
            }
        }
    }
    const validate = ()=>{
        let result = true;
        if(otp === ''){
            toast("OTP field is required")
            result = false;
        }
        if(password === ''){
            toast("Password field is required")
            result = false;
        }
        if(password_confirm === ''){
            toast("Confirm password field is required")
            result = false;
        }
        return result;
    }
  return (
    <>
                    <div className="sign-up-section">
                        <div className="sign-up-wellcome">
                            <img src={Logo} alt="Logo"/>
                            <h3>OTP</h3>
                        </div>
                        <div className="sign-up-field">
                           <form _lpchecked="1" onSubmit={sumbitOtpVerify}>
                            <div className="input-field">
                                <label for="">OTP</label>
                                <input type="text" value={otp} onChange={(e)=>setOtp(e.target.value)} placeholder="OTP"/>
                            </div>
                            <div className="input-field">
                                <label for="">Password</label>
                                <input type="text" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password"/>
                            </div>
                            <div className="input-field">
                                <label for="">Confirm Password</label>
                                <input type="text" value={password_confirm} onChange={(e)=>setPassword_confirm(e.target.value)} placeholder="Confirm Password"/>
                            </div>
                            <div className="input-field mt-3">
                                <button onClick={sumbitOtpVerify} className='{`${loading ? "disabled" : ""}`}'>
                                    Submit
                                    {
                                        loading?(<>
                                        <Loading/>
                                        </>):"Submit"
                                    }
                                </button>
                                <ToastContainer />
                                
                            </div> 
                            </form>
                        </div>
                    </div>
    </>
  );
}
export default OtpPassword;
