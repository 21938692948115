import React, { useState } from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";
function EmergencyContactInformation({setTab,setRecipientUserId,recipientUserId}){
    const [formValues, setFormValues] = useState([{ name: "",phone:"", email : "",emergancyRelToResi:""}])

    let addFormFields = () => {
        setFormValues([...formValues, { name: "",phone:"", email: "" ,emergancyRelToResi:""}])
    }

    const [primaryContactName,setPrimaryContactName] = useState("");
    const [relationshipToPrimaryContact,setRelationshipToPrimaryContact] = useState("");
    const [primaryContactNum1,setPrimaryContactNum1] = useState("");
    const [primaryContactNum2,setPrimaryContactNum2] = useState("");
    const [primaryContactEmail,setPrimaryContactEmail] = useState("");
    const [primaryContactAddress,setPrimaryContactAddress] = useState("");
    const [secondaryContactName,setSecondaryContactName] = useState("");
    const [relationshipToSecondaryContact,setRelationshipToSecondaryContact] = useState("");
    const [secondaryContactNum1,setSecondaryContactNum1] = useState("");
    const [secondaryContactNum2,setSecondaryContactNum2] = useState("");
    const [secondaryContactEmail,setSecondaryContactEmail] = useState("");
    const [secondaryContactAddress,setSecondaryContactAddress] = useState("");
    const [legalGuardianName,setLegalGuardianName] = useState("");
    const [relationshipToLegalGuardian,setRelationshipToLegalGuardian] = useState("");
    const [legalGuardianNum1,setLegalGuardianNum1] = useState("");
    const [legalGuardianNum2,setLegalGuardianNum2] = useState("");
    const [legalGuardianEmail,setLegalGuardianEmail] = useState("");
    const [legalGuardianAddress,setLegalGuardianAddress] = useState("");
    const [financialPOAName,setFinancialPOAName] = useState("");
    const [relationshipToFinancialPOA,setRelationshipToFinancialPOA] = useState("");
    const [financialPOANum1,setFinancialPOANum1] = useState("");
    const [financialPOANum2,setFinancialPOANum2] = useState("");
    const [financialPOAEmail,setFinancialPOAEmail] = useState("");
    const [financialPOAAddress,setFinancialPOAAddress] = useState("");
    const [flag_primary,setFlag_primary] = useState(false);
    const [flag_secondary,setFlag_secondary] = useState(false);
    const [flag_financial,setFlag_financial] = useState(false);
    const [flag_power,setFlag_power] = useState(false);
    const auth = JSON.parse(localStorage.getItem("user"));
    const [loading,setLoading] = useState(false)


    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }
    const emergancyStore = async (e)=>{
        e.preventDefault();
        var formdata = new FormData();       
            formValues.map((item)=>{
                if(item.name != ""){
                    formdata.append("name[]", item.name);
                }
                if(item.email != ""){
                    formdata.append("email[]", item.email);
                } 
                if(item.phone != ""){
                    formdata.append("phone[]",item.phone)
                } 
                if(item.emergancyRelToResi != ""){
                    formdata.append("emergancyRelToResi[]",item.emergancyRelToResi)
                }
            })
        formdata.append("recipientUserId", recipientUserId);
        formdata.append("primaryContactName", primaryContactName);
        formdata.append("relationshipToPrimaryContact", relationshipToPrimaryContact);
        formdata.append("primaryContactNum1", primaryContactNum1);
        formdata.append("primaryContactNum2", primaryContactNum2);
        formdata.append("primaryContactEmail", primaryContactEmail);
        formdata.append("primaryContactAddress", primaryContactAddress);
        formdata.append("secondaryContactName", secondaryContactName);
        formdata.append("relationshipToSecondaryContact", relationshipToSecondaryContact);
        formdata.append("secondaryContactNum1", secondaryContactNum1);
        formdata.append("secondaryContactNum2", secondaryContactNum2);
        formdata.append("secondaryContactEmail", secondaryContactEmail);
        formdata.append("secondaryContactAddress", secondaryContactAddress);
        formdata.append("legalGuardianName", legalGuardianName);
        formdata.append("relationshipToLegalGuardian", relationshipToLegalGuardian);
        formdata.append("legalGuardianNum1", legalGuardianNum1);
        formdata.append("legalGuardianNum2", legalGuardianNum2);
        formdata.append("legalGuardianEmail", legalGuardianEmail);
        formdata.append("legalGuardianAddress", legalGuardianAddress);
        formdata.append("financialPOAName", financialPOAName);
        formdata.append("relationshipToFinancialPOA", relationshipToFinancialPOA);
        formdata.append("financialPOANum1", financialPOANum1);
        formdata.append("financialPOANum2", financialPOANum2);
        formdata.append("financialPOAEmail", financialPOAEmail);
        formdata.append("flag_primary", flag_primary);
        formdata.append("flag_secondary", flag_secondary);
        formdata.append("flag_financial", flag_financial);
        formdata.append("flag_power", flag_power);
        setLoading(true)
        console.log(formdata);
        const apicall = await LoginService.EmergancyContactInfo(auth,formdata)
        if(apicall.state === true){
            apicall.result.json().then((resp)=>{
                setLoading(false)
                setTab(resp.tab)
            })
        }else{
            apicall.result.json().then((resp)=>{
                console.log("failed");
                setLoading(false)
            })
        }
    }
    return(
        <>
            <h5>Emergency Contact Information</h5>
            <div className="forms">
                <form action="" onSubmit={emergancyStore}>
                <div className="col-md-12">
                                                <div className="primary-em">
                                                    <ul>
                                                        <li>Primary Emergency Contact</li>
                                                    </ul>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Full Name</label>
                                                                <input type="text" placeholder="value" value={primaryContactName} onChange={(e)=>setPrimaryContactName(e.target.value)}  />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Relationship to Resident:</label>
                                                                <input type="text" placeholder="value" value={relationshipToPrimaryContact} onChange={(e)=>setRelationshipToPrimaryContact(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Primary phone number</label>
                                                                <input type="text" placeholder="value" value={primaryContactNum1} onChange={(e)=>setPrimaryContactNum1(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Secondary phone number</label>
                                                                <input type="text" placeholder="value" value={primaryContactNum2} onChange={(e)=>setPrimaryContactNum2(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Email Id</label>
                                                                <input type="text" placeholder="value" value={primaryContactEmail} onChange={(e)=>setPrimaryContactEmail(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Address</label>
                                                                <input type="text" placeholder="value" value={primaryContactAddress} onChange={(e)=>setPrimaryContactAddress(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <input type="checkbox" placeholder="value" value={flag_primary} 
                                                                onChange={(e)=>setFlag_primary(e.target.checked)}
                                                                className="emergancy_check"/>
                                                                Same as Family Caregiver
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>Secondary Emergency Contact</li>
                                                </ul>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Full Name</label>
                                                            <input type="text" placeholder="value" value={secondaryContactName} onChange={(e)=>setSecondaryContactName(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Relationship to Resident:</label>
                                                            <input type="text" placeholder="value" value={relationshipToSecondaryContact} onChange={(e)=>setRelationshipToSecondaryContact(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Primary phone number</label>
                                                            <input type="text" placeholder="value" value={secondaryContactNum1} onChange={(e)=>setSecondaryContactNum1(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Secondary phone number</label>
                                                            <input type="text" placeholder="value" value={secondaryContactNum2} onChange={(e)=>setSecondaryContactNum2(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Email Id</label>
                                                            <input type="text" placeholder="value" value={secondaryContactEmail} onChange={(e)=>setSecondaryContactEmail(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Address</label>
                                                            <input type="text" placeholder="value" value={secondaryContactAddress} onChange={(e)=>setSecondaryContactAddress(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                            <div className="input-field">
                                                                <input type="checkbox" placeholder="value" className="emergancy_check" value={flag_secondary} 
                                                                onChange={(e)=>setFlag_secondary(e.target.checked)}/>
                                                                Same as Family Caregiver
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>Power of Attorney or Legal Guardian Information</li>
                                                </ul>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Full Name</label>
                                                            <input type="text" placeholder="value" value={legalGuardianName} onChange={(e)=>setLegalGuardianName(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Relationship to Resident:</label>
                                                            <input type="text" placeholder="value" value={relationshipToLegalGuardian} onChange={(e)=>setRelationshipToLegalGuardian(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Primary phone number</label>
                                                            <input type="text" placeholder="value" value={legalGuardianNum1} onChange={(e)=>setLegalGuardianNum1(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Secondary phone number</label>
                                                            <input type="text" placeholder="value" value={legalGuardianNum2} onChange={(e)=>setLegalGuardianNum2(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Email Id</label>
                                                            <input type="text" placeholder="value" value={legalGuardianEmail} onChange={(e)=>setLegalGuardianEmail(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Address</label>
                                                            <input type="text" placeholder="value" value={legalGuardianAddress} onChange={(e)=>setLegalGuardianAddress(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                            <div className="input-field">
                                                                <input type="checkbox" placeholder="value" className="emergancy_check" value={flag_power} 
                                                                onChange={(e)=>setFlag_power(e.target.checked)}/>
                                                                Same as Family Caregiver
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>Financial Power of Attorney (If applicable):</li>
                                                </ul>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Full Name</label>
                                                            <input type="text" placeholder="value" value={financialPOAName} onChange={(e)=>setFinancialPOAName(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Relationship to Resident:</label>
                                                            <input type="text" placeholder="value" value={relationshipToFinancialPOA} onChange={(e)=>setRelationshipToFinancialPOA(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Primary phone number</label>
                                                            <input type="text" placeholder="value" value={financialPOANum1} onChange={(e)=>setFinancialPOANum1(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Secondary phone number</label>
                                                            <input type="text" placeholder="value" value={financialPOANum2} onChange={(e)=>setFinancialPOANum2(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Email Id</label>
                                                            <input type="text" placeholder="value" value={financialPOAEmail} onChange={(e)=>setFinancialPOAEmail(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label for="">Address</label>
                                                            <input type="text" placeholder="value" value={financialPOAAddress} onChange={(e)=>setFinancialPOAAddress(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                            <div className="input-field">
                                                                <input type="checkbox" placeholder="value" className="emergancy_check" value={flag_financial} 
                                                                onChange={(e)=>setFlag_financial(e.target.checked)}/>
                                                                Same as Family Caregiver
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <a href="javascript:void(0)" onClick={()=>addFormFields()}>Add Caregiver</a>
                                                    </div>
                                                </div>
                                                {
                                                    formValues.map((element, index)=>(
                                                        <>
                                                         {index==0?"":<>
                                                            <div className="row add_more_box mt-4">
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Full Name</label>
                                                                    <input type="text" placeholder="value" name="name" value={element.name || ""} onChange={e => handleChange(index, e)}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Relationship to Resident:</label>
                                                                    <input type="text" placeholder="value" name="emergancyRelToResi" value={element.emergancyRelToResi || ""} onChange={e => handleChange(index, e)}/>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Primary phone number</label>
                                                                    <input type="text" placeholder="value" name="phone" value={element.phone || ""} onChange={e => handleChange(index, e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Email Id</label>
                                                                    <input type="text" placeholder="value" name="email" value={element.email || ""} onChange={e => handleChange(index, e)}/>
                                                                </div>
                                                            </div>
                                                         </div>
                                                         </>}
                                                        </>
                                                    ))
                                                }
                                                 <SaveButton setLoading={setLoading} loading={loading}/>
                                            </div>
                                           
                </form>
            </div>
        </>
    )
}
export default EmergencyContactInformation;