import React, { useState } from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";

function SleepPattern({setTab,setRecipientUserId,recipientUserId}){
    const auth = JSON.parse(localStorage.getItem("user"));
    const [loading,setLoading] = useState(false)
    const [typical_badtime,settypical_badtime] = useState("");
    const [typical_preferred,settypical_preferred] = useState("");
    const [typical,settypical] = useState("");
    const [typical_other_text,settypical_other_text] = useState("");
    const [typical_other_textRead,settypical_other_textRead] = useState("readOnly");
    const [sleep_distur,setsleep_distur] = useState("");
    const [sleep_distur_text,setsleep_distur_text] = useState("");
    const [sleep_distur_textRead,setsleep_distur_textRead] = useState("readOnly");
    const [sleep_aids,setsleep_aids] = useState("");
    const [sleep_aids_text,setsleep_aids_text] = useState("");
    const [sleep_aids_textRead,setsleep_aids_textRead] = useState("readOnly");
    const [preferred_sleeping,setpreferred_sleeping] = useState("");
    const handleTypical = (arg) =>{
        settypical(arg)
        if(arg == '2'){
            settypical_other_textRead("")
        }else{
            settypical_other_text(""); 
            settypical_other_textRead("readOnly") 
        }
    }
    const handleSleep = (arg)=>{
        setsleep_distur(arg)
        if(arg == '5'){
            setsleep_distur_textRead("");
        }else{
            setsleep_distur_textRead("readOnly")
            setsleep_distur_text("")
        }
    }
    const handleSleepAids = (arg)=>{
        setsleep_aids(arg)
        if(arg == '3'){
            setsleep_aids_textRead("")
        }else{
            setsleep_aids_textRead("readOnly")
            setsleep_aids_text("")
        }
    }
    const sleepPatternStore = async (e)=>{
        e.preventDefault();
        var formData = new FormData();
        formData.append("recipientUserId",recipientUserId);
        formData.append("typical_badtime",typical_badtime);
        formData.append("typical_preferred",typical_preferred);
        formData.append("typical",typical);
        formData.append("typical_other_text",typical_other_text);
        formData.append("sleep_distur",sleep_distur);
        formData.append("sleep_distur_text",sleep_distur_text);
        formData.append("sleep_aids",sleep_aids);
        formData.append("sleep_aids_text",sleep_aids_text);
        formData.append("preferred_sleeping",preferred_sleeping);
            setLoading(true)
            const apicall = await LoginService.SleepPatterns(auth,formData)
            if(apicall.state === true){
                apicall.result.json().then((resp)=>{
                    setTab(resp.tab)
                    setLoading(false)
                })
            }else
            {
                apicall.result.json().then((resp)=>{
                    console.log("failed");
                    setLoading(false)
                })
            }
    }

    return(
        <>
            <form onSubmit={sleepPatternStore}>
                            <h5>Sleep Pattern and preferences</h5>
                                        <div className="forms ps-0">
                                            <p>1. Typical Sleep Routine</p>
                                            <ul className="d-flex ps-5">
                                                <li className="">
                                                    <label className="form-label">Bedtime</label>

                                                </li>
                                                <li className="list-group">
                                                    <div className="last-dose d-flex align-items-center">
                                                        <input type="text" className=" border-bottom-5" value={typical_badtime} onChange={(e)=>settypical_badtime(e.target.value)} />
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="d-flex ps-5">
                                                <li className="">
                                                    <label className="form-label">Preferred Wake time</label>

                                                </li>
                                                <li className="list-group w-50">
                                                    <div className="last-dose d-flex align-items-center">

                                                        <input type="text" className=" border-bottom-5" value={typical_preferred} onChange={(e)=>settypical_preferred(e.target.value)} />
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="d-md-flex ps-5">
                                                <li className=" w-md-25">
                                                    <input className="form-check-input" type="checkbox" name="Nap-Habits" id="Nap-Habits" value="Nap-Habits" checked={typical == '1'} onChange={(e)=>handleTypical('1')}/>
                                                    <label className="form-check-label ms-1" for="Nap-Habits">Nap Habits</label>

                                                </li>
                                                <li className=" ms-md-4 w-md-50 d-flex align-item-center" style={{ listStyle: "none" }}>
                                                    <input className="form-check-input" type="checkbox" name="Other-Restrictions" id="Other-Restrictions" value="Other-Restrictions" checked={typical == '2'} onChange={(e)=>handleTypical('2')}/>
                                                    <label className="form-check-label ms-1" for="Other-Restrictions">Other Restrictions (please explain):</label>
                                                </li>
                                                <li className="list-group w-lg-50 w-md-25">
                                                    <div className="last-dose d-flex align-items-center">

                                                        <input type="text" className="border-bottom-5" value={typical_other_text} onChange={(e)=>settypical_other_text(e.target.value)} readOnly={typical_other_textRead}/>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="my-4">
                                                <p>2. Sleep Disturbances</p>
                                                <div className="ps-4">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="None" id="None" value="None" checked={sleep_distur == '1'} onChange={(e)=>handleSleep('1')}/>
                                                        <label className="form-check-label" for="None">None</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Insomnia" id="Insomnia" value="Insomnia" checked={sleep_distur == '2'} onChange={(e)=>handleSleep('2')}/>
                                                        <label className="form-check-label" for="Insomnia">Insomnia</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Sleep-Apnea" id="Sleep-Apnea" value="Sleep-Apnea" checked={sleep_distur == '3'} onChange={(e)=>handleSleep('3')}/>
                                                        <label className="form-check-label" for="Sleep-Apnea">Sleep Apnea</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Nightmares" id="Nightmares" value="Nightmares" checked={sleep_distur == '4'} onChange={(e)=>handleSleep('4')}/>
                                                        <label className="form-check-label" for="Nightmares">Nightmares</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Other" id="Other" value="Other" checked={sleep_distur == '5'} onChange={(e)=>handleSleep('5')}/>
                                                       
                                                        <div className="last-dose d-flex align-items-center w-md-50">
                                                        <label className="form-check-label" for="Other">Other</label>
                                                            <input type="text" className="" value={sleep_distur_text} onChange={(e)=>setsleep_distur_text(e.target.value)} readOnly={sleep_distur_textRead}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="my-4">
                                                <p>3. Sleep Aids</p>
                                                <div className="ps-4">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="None" id="None" value="None" checked={sleep_aids=='1'} onChange={(e)=>handleSleepAids('1')}/>
                                                        <label className="form-check-label" for="None">None</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="CPAP" id="CPAP" value="CPAP" checked={sleep_aids=='2'} onChange={(e)=>handleSleepAids('2')}/>
                                                        <label className="form-check-label" for="CPAP">CPAP</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="Medications" id="Medications" value="Medications" checked={sleep_aids=='3'} onChange={(e)=>handleSleepAids('3')}/>
                                                        
                                                        <div className="last-dose d-flex align-items-center w-md-50">
                                                        <label className="form-check-label" for="Medications">Medications</label>
                                                            <input type="text" className="" value={sleep_aids_text} onChange={(e)=>setsleep_aids_text(e.target.value)} readOnly={sleep_aids_textRead}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my4">
                                                <div className="d-md-flex align-item-ceter">
                                                    <p>4.Special Notes Regarding Food (e.g., preferences, dislikes, mealtime routines)</p>
                                                    <div className="last-dose d-flex align-items-center w-md-50">

                                                        <input type="text" className="" value={preferred_sleeping} onChange={(e)=>setpreferred_sleeping(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <SaveButton setLoading={setLoading} loading={loading}/>
                                        </div>
            </form>                           
        </>
    )
}
export default SleepPattern