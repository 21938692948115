import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './MedicationManageModal.css'

export default function MedicationManageModal() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="" className='btn btn-sm' onClick={handleShow}>
        <img src="/images/eye-grey.svg" alt="" />
      </Button>

      <Modal show={show} onHide={handleClose} className='medication-manage-popup'>
        <Modal.Header closeButton className='popup-header'>
          <Modal.Title>View Prescriptions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="medication-manage-popup-body">
            <div className='pateint-details-card'>
                <div className='Patient-more'>
                    <div>
                        <p>Prescriptions ID:<span> #PR-002</span></p>
                    </div>
                    <div>
                        <p>Issued :<span> 06 Sep 2024</span></p>
                    </div>
                </div>
            </div>
            <div className='popup-report-box'>
                <div className="resident mt-3">
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                        <h5>Prescription Details</h5>
                    </div>

                    <div className="table-responsive">
                        <table className="table-stripedd table table-bordered table-hover align-middle">
                            <thead className="table">
                                <tr>
                                <th scope="col">Name<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Dosage<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Frequency<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Duration<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                    <th scope="col">Timing<img className='updown-arrow' src="/images/arrow-down-up.svg" alt="" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Ecosprin 75MG</td>
                                    <td>75mg (Oral Tab)</td>
                                    <td>1-0-0-1</td>
                                    <td>1 month</td>
                                    <td>Before Meal</td>
                                    
                                </tr>
                                <tr>
                                    <td>Ecosprin 75MG</td>
                                    <td>75mg (Oral Tab)</td>
                                    <td>1-0-0-1</td>
                                    <td>1 month</td>
                                    <td>Before Meal</td>
                                    
                                </tr>
                                <tr>
                                    <td>Ecosprin 75MG</td>
                                    <td>75mg (Oral Tab)</td>
                                    <td>1-0-0-1</td>
                                    <td>1 month</td>
                                    <td>Before Meal</td>
                                    
                                </tr>
                                <tr>
                                    <td>Ecosprin 75MG</td>
                                    <td>75mg (Oral Tab)</td>
                                    <td>1-0-0-1</td>
                                    <td>1 month</td>
                                    <td>Before Meal</td>
                                    
                                </tr>
                                <tr>
                                    <td>Ecosprin 75MG</td>
                                    <td>75mg (Oral Tab)</td>
                                    <td>1-0-0-1</td>
                                    <td>1 month</td>
                                    <td>Before Meal</td>
                                    
                                </tr>
                                {/* <!-- Add more rows as needed --> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
