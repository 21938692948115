import React, { useState } from 'react';
import './registration.css';
import Dashboard from '../Dashboard/Dashboard';
import EmergencyContactInformation from './EmergencyContactInformation';
import DetailsMedicalHistory from './DetailsMedicalHistory';
import FunctionalAbility from './FunctionalAbility';
import FunctionalAbility1 from './FunctionalAbility1';
import NutritionalNeeds from './NutritionalNeeds';
import SocialHistory from './SocialHistory';
import SleepPattern from './SleepPattern';
import FinancialInformation from './FinancialInformation';
import OfficeUseOnly from './OfficeUseOnly';
import ConsentSignatures from './ConsentSignatures';
import PersonalInformation from './PersonalInformation';
export default function Registration() {
    const [tab,setTab] = useState(0);
    const [recipientUserId,setRecipientUserId] = useState("");
    console.log(tab)
    return (
        <>
            <Dashboard />
            <div className='main-content'>
                <div className='container-fluid p-0 m-0'>
                    <div className="home">
                        <div className="col-md-12">
                            <div className="new-reg-form">
                                <h6>New Registration Form</h6>
                                <p>Create a new profile</p>
                                <div className="form-tab">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab === 0?'active':''}`} id="home-tab" data-bs-toggle="tab"
                                                data-bs-target="#Personal-Information" type="button" role="tab"
                                                aria-controls="Personal-Information" aria-selected="true" >Personal
                                                Information</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab > 1?'':tab===1?'active':'disabled'}`} id="Emergency-Contact-Information-tab" data-bs-toggle="tab"
                                                data-bs-target="#Emergency-Contact-Information" type="button" role="tab"
                                                aria-controls="Emergency-Contact-Information" aria-selected="false" >Emergency
                                                Contact Information</button>
                                        </li>
                                        <li className="nav-item "  role="presentation">
                                            <button className={`nav-link ${tab > 2?'':tab === 2?'active':'disabled'}`} id="contact-tab" data-bs-toggle="tab"
                                                data-bs-target="#contact" type="button" role="tab" aria-controls="contact"
                                                aria-selected="false" >Detailed
                                                Medical History</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab > 3?'':tab === 3?'active':'disabled'}`} id="Functional-Ability-And-ADLs-tab" data-bs-toggle="tab"
                                                data-bs-target="#Functional-Ability-And-ADLs" type="button" role="tab" aria-controls="Functional-Ability-And-ADLs"
                                                aria-selected="false">Functional Ability And ADLs</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab > 4?'':tab === 4?'active':'disabled'}`} id="Functional-Ability-five-tab" data-bs-toggle="tab"
                                                data-bs-target="#Functional-Ability-five" type="button" role="tab" aria-controls="Functional-Ability-five"
                                                aria-selected="false">Functional Ability And ADLs</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab > 5?'':tab === 5?'active':'disabled'}`} id="Nutritional-needs-tab" data-bs-toggle="tab"
                                                data-bs-target="#Nutritional-needs" type="button" role="tab" aria-controls="Nutritional-needs"
                                                aria-selected="false">Nutritional needs and dietary preference</button>
                                        </li>
                                        <li className="nav-item ms-3" role="presentation">
                                            <button className={`nav-link ${tab > 6?'':tab === 6?'active':'disabled'}`} id="#Sleep-Pattern-and-preferences-tab" data-bs-toggle="tab"
                                                data-bs-target="#Sleep-Pattern-and-preferences" type="button" role="tab" aria-controls="#Sleep-Pattern-and-preferences"
                                                aria-selected="false">Sleep Pattern and preferences</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab > 7?'':tab === 7?'active':'disabled'}`} id="#Social-history-and-activities-tab" data-bs-toggle="tab"
                                                data-bs-target="#Social-history-and-activities" type="button" role="tab" aria-controls="#Social-history-and-activities"
                                                aria-selected="false">Social history and activities</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab > 8?'':tab === 8?'active':'disabled'}`} id="#Financial-information-tab" data-bs-toggle="tab"
                                                data-bs-target="#Financial-information" type="button" role="tab" aria-controls="#Financial-information"
                                                aria-selected="false">Financial information and payment details</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab > 9?'':tab === 9?'active':'disabled'}`} id="#Consent-signatures-tab" data-bs-toggle="tab"
                                                data-bs-target="#Consent-signatures" type="button" role="tab" aria-controls="#Consent-signatures"
                                                aria-selected="false">Consent and signatures</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${tab > 10?'':tab === 10?'active':'disabled'}`} id="#office-use-only-tab" data-bs-toggle="tab"
                                                data-bs-target="#office-use-only" type="button" role="tab" aria-controls="#office-use-only"
                                                aria-selected="false">For office use only</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="form">
                                <div className="tab-content" id="myTabContent">
                                    <div className={`tab-pane fade show ${tab === 0?'active':''}`} id="Personal-Information" role="tabpanel"
                                        aria-labelledby="home-tab">
                                         <PersonalInformation setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                        
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 1?'active':''}`} id="Emergency-Contact-Information" role="tabpanel"
                                        aria-labelledby="Emergency-Contact-Information-tab">
                                        <EmergencyContactInformation setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 2?'active':''}`} id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                        <DetailsMedicalHistory setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 3?'active':''}`} id="Functional-Ability-And-ADLs" role="tabpanel" aria-labelledby="Functional-Ability-And-ADLs-tab">
                                       <FunctionalAbility setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 4?'active':''}`} id="Functional-Ability-five" role="tabpanel" aria-labelledby="Functional-Ability-five-tab">
                                       <FunctionalAbility1 setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 5?'active':''}`} id="Nutritional-needs" role="tabpanel" aria-labelledby="Nutritional-needs-tab">
                                        <NutritionalNeeds setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                        
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 6?'active':''}`} id="Sleep-Pattern-and-preferences" role="tabpanel" aria-labelledby="Sleep-Pattern-and-preferences-tab">
                                        <SleepPattern setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>  
                                        
                                       
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 7?'active':''}`} id="Social-history-and-activities" role="tabpanel" aria-labelledby="Social-history-and-activities-tab">
                                        <SocialHistory setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                       
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 8?'active':''}`} id="Financial-information" role="tabpanel" aria-labelledby="Financial-information-tab">
                                        <FinancialInformation setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 9?'active':''}`} id="Consent-signatures" role="tabpanel" aria-labelledby="Consent-signatures-tab">
                                        <ConsentSignatures setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                    </div>
                                    <div className={`tab-pane fade show ${tab === 10?'active':''}`} id="office-use-only" role="tabpanel" aria-labelledby="office-use-only-tab">
                                        <OfficeUseOnly setTab={setTab} setRecipientUserId={setRecipientUserId} recipientUserId={recipientUserId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}