import React from 'react'
import FamilyDashboard from '../Dashboard/Dashboard'
import './MedicationManage.css'
import MedicationManageModal from '../Modals/MedicationManagement/MedicationManageModal'

export default function MedicationManage() {
  return (
    <>
      <FamilyDashboard/>
      <div className="main-content medication-manage-main">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="row">
                <div className='page-header'>
                    <h2>Medication Management</h2>
                </div>
              <div className="col-md-12">
                <div className="resident">
                  <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                    <h5>Prescriptions</h5>
                  </div>
                  <div className="table-responsive">
                    <table className="table-stripedd table table-bordered table-hover align-middle">
                      <thead className="table">
                        <tr>
                          <th scope="col">
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              <span
                                style={{
                                  color: "#fff",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                ID
                              </span>{" "}
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </div>
                          </th>
                          <th scope="col">
                            Name
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Date
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                           Prescriped by
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                          Dosage
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                          Frequency
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                          Duration
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                          Timing
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ecosprin 75MG</td>
                          <td>12-Jan-2024</td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>75mg (Oral Tab)</td>
                          <td>1-0-0-1</td>
                          <td>1 month</td>
                          <td>
                            <span>Before Meal</span>
                          </td>
                          <td className="table-fix">
                            <MedicationManageModal/>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/document-download-grey.svg" alt="" />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ecosprin 75MG</td>
                          <td>12-Jan-2024</td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>75mg (Oral Tab)</td>
                          <td>1-0-0-1</td>
                          <td>1 month</td>
                          <td>
                            <span>Before Meal</span>
                          </td>
                          <td className="table-fix">
                            <MedicationManageModal/>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/document-download-grey.svg" alt="" />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ecosprin 75MG</td>
                          <td>12-Jan-2024</td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>75mg (Oral Tab)</td>
                          <td>1-0-0-1</td>
                          <td>1 month</td>
                          <td>
                            <span>Before Meal</span>
                          </td>
                          <td className="table-fix">
                            <MedicationManageModal/>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/document-download-grey.svg" alt="" />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ecosprin 75MG</td>
                          <td>12-Jan-2024</td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>75mg (Oral Tab)</td>
                          <td>1-0-0-1</td>
                          <td>1 month</td>
                          <td>
                            <span>Before Meal</span>
                          </td>
                          <td className="table-fix">
                            <MedicationManageModal/>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/document-download-grey.svg" alt="" />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ecosprin 75MG</td>
                          <td>12-Jan-2024</td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>75mg (Oral Tab)</td>
                          <td>1-0-0-1</td>
                          <td>1 month</td>
                          <td>
                            <span>Before Meal</span>
                          </td>
                          <td className="table-fix">
                            <MedicationManageModal/>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/document-download-grey.svg" alt="" />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ecosprin 75MG</td>
                          <td>12-Jan-2024</td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>75mg (Oral Tab)</td>
                          <td>1-0-0-1</td>
                          <td>1 month</td>
                          <td>
                            <span>Before Meal</span>
                          </td>
                          <td className="table-fix">
                            <MedicationManageModal/>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/document-download-grey.svg" alt="" />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ecosprin 75MG</td>
                          <td>12-Jan-2024</td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>75mg (Oral Tab)</td>
                          <td>1-0-0-1</td>
                          <td>1 month</td>
                          <td>
                            <span>Before Meal</span>
                          </td>
                          <td className="table-fix">
                            <MedicationManageModal/>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/document-download-grey.svg" alt="" />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Ecosprin 75MG</td>
                          <td>12-Jan-2024</td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>75mg (Oral Tab)</td>
                          <td>1-0-0-1</td>
                          <td>1 month</td>
                          <td>
                            <span>Before Meal</span>
                          </td>
                          <td className="table-fix">
                            <MedicationManageModal/>

                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src="/images/document-download-grey.svg" alt="" />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        {/* <!-- Add more rows as needed --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="staff-pagination my-3 d-none">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                          <img src="/images/arrow-left-p.svg"/>
                        </a>
                      </li>
                      <li class="page-item active" aria-current="page">
                        <a class="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          4
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          5
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link p-icon-next" href="#" aria-label="Next">
                        <img src="/images/arrow-left-p.svg"/>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
