import React,{useState} from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";
function FunctionalAbility({setTab,setRecipientUserId,recipientUserId}){
    const auth = JSON.parse(localStorage.getItem("user"));
    const [mobility,setMobility] = useState("")
    const [mobilityInp,setMobilityInp] = useState("")
    const [mobilityInp1,setMobilityInp1] = useState("")
    const [walking,setWalking] = useState("")
    const [walkingInp,setWalkingInp] = useState("")
    const [appoint,setAppoint] = useState("")
    const [loading,setLoading] = useState(false)
    const [readOnly1,setReadOnly] = useState("readOnly")
    const [readOnly2,setReadOnly2] = useState("readOnly")
    const handelMobiltyAssess = (arg) =>{
        setMobility(arg)
        if(arg == 'fully-independent' || arg == 'fully-dependent'){
            setMobilityInp("")
            setMobilityInp1("")
            setReadOnly("readOnly")
            setReadOnly2("readOnly")
        }
        if(arg == 'minimal-assistance'){
            setMobilityInp1("")
            setReadOnly("")
            setReadOnly2("readOnly")
        }
        if(arg == 'moderate-assistance'){
            setMobilityInp("")
            setReadOnly("readOnly")
            setReadOnly2("")
        }
    }
    const handleWalking = (arg)=>{
        setWalking(arg);
        if(arg == 'other'){
            setReadOnly("")
        }else{
            setReadOnly("readOnly")
            setWalkingInp("");
        }
    }
    const funcationAbilityStore = async (e)=>{
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("recipientUserId",recipientUserId);
        formdata.append("mobilityAssessment", mobility);
        if(mobility == 'minimal-assistance'){
            formdata.append("mobilityAssessmentInput", mobilityInp);

        }else{
            formdata.append("mobilityAssessmentInput", mobilityInp1);

        }
        formdata.append("walkingAid", walking);
        formdata.append("walkingAidInput", walkingInp);
        formdata.append("appointmentSchedule", appoint);
        
        setLoading(true)
        const apicall = await LoginService.FuncationAbilityAids(auth,formdata)
        if(apicall.state === true){
            apicall.result.json().then((resp)=>{
                setTab(resp.tab)
                setLoading(false)
            })
        }else{
            apicall.result.json().then((resp)=>{
                console.log("failed");
                setLoading(false)
            })
        }
    }

    return(
        <>
                                    <h5 className="w-auto px-2">Functional Ability And ADLs (Activities of Daily Living)</h5>
                                        <div className="forms">
                                            <form className="adls-form" onSubmit={funcationAbilityStore}>
                                                <fieldset className="">
                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Mobility Assessment</li>
                                                        </ul>

                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="fully-independent" value="fully-independent" checked={mobility === 'fully-independent'} onChange={()=>handelMobiltyAssess("fully-independent")}/>
                                                            <label className="form-check-label" for="fully-independent">
                                                                Fully Independent
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="minimal-assistance" value="minimal-assistance"  checked={mobility === 'minimal-assistance'} onChange={()=>handelMobiltyAssess("minimal-assistance")} />
                                                           
                                                            <div className="last-dose d-flex align-items-center ms-2">
                                                                <label className="form-check-label" for="minimal-assistance">
                                                                Requires minimal assistance 
                                                                </label>
                                                                <input type="text" className="" value={mobilityInp} onChange={(e)=>setMobilityInp(e.target.value)} readOnly={readOnly1}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="moderate-assistance" value="moderate-assistance"  checked={mobility === 'moderate-assistance'} onChange={()=>handelMobiltyAssess("moderate-assistance")}/>
                                                            
                                                            <div className="last-dose d-flex align-items-center ms-2">
                                                                <label className="form-check-label" for="minimal-assistance">
                                                                Requires moderate assistance
                                                                </label>
                                                                <input type="text" className="" value={mobilityInp1} onChange={(e)=>setMobilityInp1(e.target.value)} readOnly={readOnly2}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="fully-dependent" value="fully-dependent"  checked={mobility === 'fully-dependent'} onChange={()=>handelMobiltyAssess("fully-dependent")}/>
                                                            <label className="form-check-label" for="fully-dependent">
                                                                Fully dependent
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Walking aids used</li>
                                                        </ul>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="none" value="none" checked={walking === 'none'} onChange={()=>handleWalking("none")}/>
                                                            <label className="form-check-label" for="none">
                                                                None
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="cane" value="cane" checked={walking === 'cane'} onChange={()=>handleWalking("cane")} />
                                                            <label className="form-check-label" for="cane">
                                                                Cane
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="walker" value="walker" checked={walking === 'walker'} onChange={()=>handleWalking("walker")}/>
                                                            <label className="form-check-label" for="walker">
                                                                Walker
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="wheelchair" value="wheelchair" checked={walking === 'wheelchair'} onChange={()=>handleWalking("wheelchair")} />
                                                            <label className="form-check-label" for="wheelchair">
                                                                Wheelchair
                                                            </label>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="other" value="other" checked={walking === 'other'} onChange={()=>handleWalking("other")} />
                                                            <div className="last-dose d-flex align-items-center ms-2">
                                                                <label for="other">
                                                                    Other
                                                                </label>
                                                                <input type="text" className="" value={walkingInp} onChange={(e)=>setWalkingInp(e.target.value)} readOnly={readOnly1} />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Appointment Scheduled with Physiotherapist</li>
                                                        </ul>
                                                        <div className="d-flex">
                                                            <input className="form-check-input" type="checkbox" name="appointment" id="appointment-yes" value="yes" checked={appoint === '1'} onChange={()=>setAppoint("1")} />
                                                            <label className="form-check-label ms-1 me-2" for="appointment-yes">
                                                                Yes
                                                            </label>

                                                            <input className="form-check-input" type="checkbox" name="appointment" id="appointment-no" value="no" checked={appoint === '0'} onChange={()=>setAppoint("0")}/>
                                                            <label className="form-check-label ms-1 me-2" for="appointment-no">
                                                                No
                                                            </label>

                                                        </div>
                                                    </div>

                                                    <SaveButton setLoading={setLoading} loading={loading}/>
                                                    </fieldset>
                                            </form>
                                        </div>
        </>
    )
}
export default FunctionalAbility