import React,{useState} from 'react';
import './forgot.css';
import Logo from '../../../images/endwelllcare-logo.png';
import LoginImg from '../../../images/sign-up.jpeg';
import { useNavigate } from "react-router-dom";
import TeamLogin from "../../../Service/Team/Login/LoginService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpPassword from './OtpPassword';
import Loading from '../Loader/Loading';
export default function Forgot(){
    const navigate = useNavigate();
    const [email,setEmail] = useState("") 
    const [isForgot,setIsForgot] = useState(true)
    const [isOtp,setIsOtp] =useState(false);  
    const [loading,setLoading] = useState(false) 
    const sumbitLogin = async (e)=>{
        e.preventDefault();
        setLoading(true)
        if(validate()){
            let post = {email:email};
            const apicall = await TeamLogin.ForgotPassword(post)
            if (apicall.state === true) {
                apicall.result.json().then((resp1) => {
                if (resp1.status == 200) {
                  setIsOtp(true)
                  setIsForgot(false)
                  setLoading(false)
                }
                });
            } else {
                apicall.result.json().then((resp1) => {
                    if (resp1.status === 400) {
                        toast(resp1.error)
                        setLoading(false)
                    } 
                });
            }
        }
    }
    const validate = ()=>{
        let result = true;
        if(email === ''){
            toast("Email field is required")
            result = false;
            setLoading(false)
        }
        return result;
    }
    return(
    <div className="sign-up">
        <div className="container-lg">
            <div className="row">
                <div className="col-md-6">
                    <div className='left-img position-relative'>
                        <img src={LoginImg} alt='left img'/>
                        <div className='left-img-text'>
                        <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    {isForgot &&  <>
                        <div className="sign-up-section">
                            <div className="sign-up-wellcome">
                                <img src={Logo} alt="Logo"/>
                                <h3>Forgot Password</h3>
                                <p>Enter your details to login</p>
                            </div>
                            <div className="sign-up-field">
                            <form _lpchecked="1" onSubmit={sumbitLogin}>
                            <div className="input-field">
                                    <label for="">Email</label>
                                    <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email"/>
                                </div>
                                <div className="input-field mt-3">
                                    <button onClick={sumbitLogin} className='{`${loading ? "disabled" : ""}`}'>
                                        {
                                            loading ?(<>
                                                 <Loading/>
                                            </>):"Submit"
                                        }
                                        
                                    </button>
                                    <ToastContainer />
                                    
                                </div> 
                                </form>
                            </div>
                        </div>
                    </>}
                   
                    {isOtp &&  <OtpPassword email={email}/>}
                   
                </div>
                
            </div>
        </div>
    </div>
    );
}