import React from "react";
import FamilyDashboard from "../Dashboard/Dashboard";
import "./ActivityTracker.css";
import MyCalendar from "../../Calendar/Calendar";
import Appointments from "../../AppointmentsCard/Appointments";
import Notes from "../../Notes/Notes";

export default function ActivityTracker() {
  return (
    <>
      <FamilyDashboard />
      <div className="main-content activity-tracker-box">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="row">
              <div className="page-header">
                <h2>Activity Tracker</h2>
              </div>
              <div className="col-md-8">
                <div className="resident">
                  <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                    <h5>Doctor Visit Summary</h5>
                    <div className="left-content">
                      <div className="input-field position-relative">
                        <input
                          type="text"
                          className="form-control d-inline-block"
                          placeholder="Search Summary"
                        />
                        <span>
                          <img src="/images/search-icon.png" />
                        </span>
                      </div>
                      <div className="filtericon">
                        <button>
                          <img src="/images/filter-icon.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table-stripedd table table-bordered table-hover align-middle">
                      <thead className="table">
                        <tr>
                          <th scope="col">
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              <span
                                style={{
                                  color: "#fff",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                ID
                              </span>{" "}
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </div>
                          </th>
                          <th scope="col">
                            Doctor
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Date
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Prescription
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Outcome
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>12-Jan-24</td>
                          <td>Ecosprin 75MG</td>
                          <td>Need to sleep properly</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/images/document-download-grey.svg"
                                alt=""
                              />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>12-Jan-24</td>
                          <td>Ecosprin 75MG</td>
                          <td>Need to sleep properly</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/images/document-download-grey.svg"
                                alt=""
                              />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>12-Jan-24</td>
                          <td>Ecosprin 75MG</td>
                          <td>Need to sleep properly</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/images/document-download-grey.svg"
                                alt=""
                              />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>12-Jan-24</td>
                          <td>Ecosprin 75MG</td>
                          <td>Need to sleep properly</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/images/document-download-grey.svg"
                                alt=""
                              />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>12-Jan-24</td>
                          <td>Ecosprin 75MG</td>
                          <td>Need to sleep properly</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/images/document-download-grey.svg"
                                alt=""
                              />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        {/* <!-- Add more rows as needed --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="resident mt_20">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>Physiotherapy Sessions</h5>
                    <div className="left-content">
                      <div className="input-field position-relative">
                        <input
                          type="text"
                          className="form-control d-inline-block"
                          placeholder="Search Session"
                        />
                        <span>
                          <img src="/images/search-icon.png" />
                        </span>
                      </div>
                      <div className="filtericon">
                        <button>
                          <img src="/images/filter-icon.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table-stripedd table table-bordered table-hover align-middle">
                      <thead className="table">
                        <tr>
                          <th scope="col">
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              <span
                                style={{
                                  color: "#fff",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                ID
                              </span>{" "}
                              <img
                                className="updown-arrow"
                                src="/images/arrow-down-up.svg"
                                alt=""
                              />
                            </div>
                          </th>
                          <th scope="col">
                            Doctor
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Date
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">
                            Outcome
                            <img
                              className="updown-arrow"
                              src="/images/arrow-down-up.svg"
                              alt=""
                            />
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>12-Jan-24</td>
                          <td>Need to sleep properly</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/images/document-download-grey.svg"
                                alt=""
                              />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>12-Jan-24</td>
                          <td>Need to sleep properly</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/images/document-download-grey.svg"
                                alt=""
                              />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="checkbox checbox-trans">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              P-002
                            </div>
                          </td>
                          <td>Dr.Sanjay Bansal</td>
                          <td>12-Jan-24</td>
                          <td>Need to sleep properly</td>
                          <td className="table-fix">
                            <button className="btn btn-sm ">
                              <img src="/images/eye-grey.svg" alt="" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/images/document-download-grey.svg"
                                alt=""
                              />
                            </button>
                            <button className="btn btn-sm">
                              <img src="/images/trash-grey.svg" alt="" />
                            </button>
                          </td>
                        </tr>
                        {/* <!-- Add more rows as needed --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Calender">
                  <MyCalendar />
                </div>
                <div className="mt_20 ">
                  <Appointments />
                </div>
              </div>
            </div>
            <div className="mt_20">
              <div className="row">
                <div className="col-md-7">
                  <div className="notes-box">
                    <Notes
                      noteHeading="Physiotherapy Notes"
                      aLink="View All"
                      btnType="Add Notes"
                      contentHeadingOne="Fall Detection"
                      descriptionOne="Mohan's recent walk resulted in a fall, while it wasn't serious. Further investigation would be required.r"
                      contentHeadingTwo="Missed Medication"
                      descriptionTwo="Mohan recently missed his medication in the evening. The caretaker made sure within 30minutes that he had taken it then."
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="notes-box">
                    <Notes
                      noteHeading="Other Notes"
                      aLink="View All"
                      btnType="Add Notes"
                      contentHeadingOne="Fall Detection"
                      descriptionOne="Mohan's recent walk resulted in a fall, while it wasn't serious. Further investigation would be required.r"
                      contentHeadingTwo="Missed Medication"
                      descriptionTwo="Mohan recently missed his medication in the evening. The caretaker made sure within 30minutes that he had taken it then."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
