import React, { useState } from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";
function NutritionalNeeds({setTab,setRecipientUserId,recipientUserId}){
const auth = JSON.parse(localStorage.getItem("user"));
const [dietary_pref,setdietary_pref] = useState("");
const [dietary_prefRead,setdietary_prefRead] = useState("readOnly");
const [other,setother] = useState("");
const [specific_dietary,setspecific_dietary] = useState([]);
const [other_restriction,setother_restriction] = useState("");
const [other_restrictionRead,setother_restrictionRead] = useState("readOnly");
const [food_allergies,setfood_allergies] = useState("");
const [special_notes,setspecial_notes] = useState("");
const [hydration_pref,sethydration_pref] = useState([]);
const [restricted_reason,setrestricted_reason] = useState("");
const [restricted_reasonRead,setrestricted_reasonRead] = useState("readOnly");
const [loading,setLoading] = useState(false)


const handleDietary = (arg) =>{
    setdietary_pref(arg)
    if(arg == 4){
        setdietary_prefRead("");
    }else{
        setdietary_prefRead("readOnly")
        setother("")
    }
}
const handleSpecificDie = (e)=>{
    const {value,checked} = e.target;
    if(checked){
        if(value == '6'){
            setother_restrictionRead("")
        }
        else{
            setother_restrictionRead("readOnly")
        }
        setspecific_dietary([...specific_dietary,value]);
    }else{
        setspecific_dietary(specific_dietary.filter((e)=> e !== value));
        setother_restrictionRead("readOnly")
        setother_restriction("")
    }
    // let data = specific_dietary
    // data.push(e.target.value)
    // setspecific_dietary(data);
}

const handleHydration = (e)=>{
    const {value,checked} = e.target;
    if(checked){
        if(value == '6'){
            setrestricted_reasonRead("")
        }
        else{
            setrestricted_reasonRead("readOnly")
        }
        sethydration_pref([...hydration_pref,value]);
    }else{
        sethydration_pref(hydration_pref.filter((e)=> e !== value));
        setrestricted_reasonRead("readOnly")
        setrestricted_reason("")
    }
    // let data = specific_dietary
    // data.push(e.target.value)
    // setspecific_dietary(data);
}


const NutritionalStore = async (e) => {
e.preventDefault();
   var formData = new FormData();
   formData.append("recipientUserId",recipientUserId);
   formData.append("dietary_pref",dietary_pref);
   formData.append("other",other);
   formData.append("specific_dietary[]",specific_dietary);
   formData.append("other_restriction",other_restriction);
   formData.append("food_allergies",food_allergies);
   formData.append("special_notes",special_notes);
   formData.append("hydration_pref[]",hydration_pref);
   formData.append("restricted_reason",restricted_reason);
    setLoading(true)
    const apicall = await LoginService.Nutrational(auth,formData)
    if(apicall.state === true){
        apicall.result.json().then((resp)=>{
            setTab(resp.tab)
            setLoading(false)
        })
    }else
    {
        apicall.result.json().then((resp)=>{
            console.log("failed");
            setLoading(false)
        })
    }
}
    return(
        <>
        <h5>Nutritional needs and dietary preference</h5>
                                        <div className="forms">
                                            <div className="">
                                                <form className="" onSubmit={NutritionalStore}>
                                                    <fieldset className="">


                                                        <div className="mb-4">
                                                            <ul>
                                                                <li>Dietary Preferences</li>
                                                            </ul>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="dietary-preference" id="vegetarian" value="vegetarian" checked={dietary_pref == '1'} onChange={(e)=>handleDietary('1')}/>
                                                                <label className="form-check-label" for="vegetarian">
                                                                    Vegetarian
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="dietary-preference" id="non-vegetarian" value="non-vegetarian" checked={dietary_pref == '2'} onChange={(e)=>handleDietary('2')}/>
                                                                <label className="form-check-label" for="non-vegetarian">
                                                                    Non-Vegetarian
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="dietary-preference" id="vegan" value="vegan" checked={dietary_pref == '3'} onChange={(e)=>handleDietary('3')}/>
                                                                <label className="form-check-label" for="vegan">
                                                                    Vegan
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <input className="form-check-input" type="checkbox" name="dietary-preference" id="other" value="other" checked={dietary_pref == '4'} onChange={(e)=>handleDietary('4')}/>
                                                                <label className="form-check-label ms-1" for="other">
                                                                    Other
                                                                </label>
                                                                <div className="last-dose d-flex align-items-center">
                                                                    <input type="text" value={other} className="" onChange={(e)=>setother(e.target.value)} readOnly={dietary_prefRead}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mb-4">
                                                            <ul>
                                                                <li>Specific Dietary Requirements/Restrictions</li>
                                                            </ul>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="diabetic" value="1" onChange={(e)=>handleSpecificDie(e)} />
                                                                <label className="form-check-label" for="diabetic">Diabetic</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="low-sodium" value="2" onChange={(e)=>handleSpecificDie(e)}/>
                                                                <label className="form-check-label" for="low-sodium">Low Sodium</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="low-fat" value="3" onChange={(e)=>handleSpecificDie(e)}/>
                                                                <label className="form-check-label" for="low-fat">Low Fat</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="high-protein" value="4" onChange={(e)=>handleSpecificDie(e)}/>
                                                                <label className="form-check-label" for="high-protein">High Protein</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="soft-diet" value="5" onChange={(e)=>handleSpecificDie(e)}/>
                                                                <label className="form-check-label" for="soft-diet">Soft Diet</label>
                                                            </div>
                                                            <div className="d-md-flex align-items-center">
                                                                <div className="d-flex">
                                                                    <input className="form-check-input" type="checkbox" id="other-diet-restriction" value="6" onChange={(e)=>handleSpecificDie(e)}/>
                                                                    <label className="form-check-label ms-1" for="other-diet-restriction">Other Restrictions (please explain)</label>
                                                                </div>
                                                                <div className="last-dose d-flex align-items-center ms-1 w-sm-100">
                                                                    <input type="text" className="w-sm-10" value={other_restriction} onChange={(e)=>setother_restriction(e.target.value)} readOnly={other_restrictionRead}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mb-4">
                                                            <ul className="d-flex">
                                                                <li className="">
                                                                    <label className="form-label">Food Allergies</label>

                                                                </li>
                                                                <li className="list-group w-50">
                                                                    <div className="last-dose d-flex align-items-center">
                                                                        <input type="text" className="w-100" value={food_allergies} onChange={(e)=>setfood_allergies(e.target.value)}/>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul className="d-md-flex">
                                                                <li className="">
                                                                    <label className="form-label">Special Notes Regarding Food (e.g., preferences, dislikes, mealtime routines)</label>

                                                                </li>
                                                                <li className="list-group w-lg-25">
                                                                    <div className="last-dose d-flex align-items-center">

                                                                        <input type="text" className=" w-100" value={special_notes} onChange={(e)=>setspecial_notes(e.target.value)}/>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                        </div>

                                                        <div className="mb-1">
                                                            <ul>
                                                                <li>Hydration Preferences</li>
                                                            </ul>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="hydration-preference" id="normal-fluid-intake" value="1" onChange={(e)=>handleHydration(e)}/>
                                                                <label className="form-check-label" for="normal-fluid-intake">Normal Fluid Intake</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Requires-Encouragement" id="Requires-Encouragement" value="2" onChange={(e)=>handleHydration(e)} />
                                                                <label className="form-check-label" for="Requires-Encouragement">Requires Encouragement</label>
                                                            </div>

                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="hydration-preference" id="low-fluid" value="3" onChange={(e)=>handleHydration(e)} />
                                                                <label className="form-check-label" for="low-fluid">Low Fluid</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="hydration-preference" id="high-protein-fluid" value="4" onChange={(e)=>handleHydration(e)}/>
                                                                <label className="form-check-label" for="high-protein-fluid">High Protein</label>

                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="hydration-preference" id="soft-diet-fluid" value="5" onChange={(e)=>handleHydration(e)}/>
                                                                <label className="form-check-label" for="soft-diet-fluid">Soft Diet</label>
                                                            </div>
                                                            <div className="d-md-flex align-items-center">
                                                                <div className="d-flex">
                                                                    <input className="form-check-input" type="checkbox" name="hydration-preference" id="restricted" value="6" onChange={(e)=>handleHydration(e)}/>
                                                                    <label className="form-check-label ms-1" for="restricted">Restricted Fluid Intake(Reason)</label>
                                                                </div>
                                                                <div className="last-dose d-flex align-items-center ms-1 w-sm-100">
                                                                    <input type="text" className="w-sm-10" value={restricted_reason} onChange={(e)=>setrestricted_reason(e.target.value)} readOnly={restricted_reasonRead}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <SaveButton setLoading={setLoading} loading={loading}/>
                                                    </fieldset>
                                                </form>
                                            </div>

                                        </div>
        </>
    )
}
export default NutritionalNeeds