import React from "react";
import FamilyDashboard from "../Dashboard/Dashboard";
import "./HealthMonitoring.css";
import { BarChart } from "@mui/x-charts";
import Stack from "@mui/material/Stack";
import { LineChart } from "@mui/x-charts/LineChart";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const data = [20, 70, 30, 110, 82, 180, 70];
const xData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const cData = [0, 1200, 950, 700, 900, 600, 50, 0, 0];
const wLabels = ["6AM", "10AM", "2PM", "6PM", "10PM", "5PM", "6pm", "8PM"];

const pData = [140, 135, 120, 160, 131, 142, 105];
const uData = [89, 64, 75, 85, 73, 68, 55];
const xLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const gData = [140, 135, 120, 160, 131, 142, 105];
const bData = [89, 64, 75, 85, 73, 68, 55];
const aLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"];

export default function HealthMonitoring() {
  return (
    <>
      <FamilyDashboard />
      <div className="main-content health-monitoring-box">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="page-header d-flex justify-content-between align-items-baseline">
              <h2>Health Statistics</h2>
            </div>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="card-dsgn heart-rate h-100">
                  <div className="heart-rate-top d-flex align-items-center justify-content-between">
                    <img src="/images/blood.svg" alt="" />
                    <div className="input-field">
                      <select>
                        <option value="">Today</option>
                      </select>
                    </div>
                  </div>
                  <div className="heart-inner d-flex align-items-center justify-content-between">
                    <div>
                      <h3>Heart Rate</h3>
                      <p>Average heart rate over the whole day is 82 BPM</p>
                    </div>
                    <h5>
                      82 <span>BPM</span>
                    </h5>
                  </div>
                  <div className="chart-box mt-5">
                    <Stack sx={{ width: "100%" }}>
                      <LineChart
                        xAxis={[
                          { data: xData, scaleType: "point",},
                        ]}
                        series={[
                          {
                            data,
                            showMark: false,
                            area: true,
                            connectNulls: true,
                            color: "#F11C0E",
                          },
                        ]}
                        height={200}
                        margin={{ top: 10, bottom: 20 }}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-dsgn heart-rate">
                  <div className="heart-rate-top d-flex align-items-center justify-content-between">
                    <img src="/images/foot.svg" alt="" />
                    <div className="input-field">
                      <select>
                        <option value="">Today</option>
                      </select>
                    </div>
                  </div>
                  <div className="heart-inner d-flex align-items-center justify-content-between">
                    <div>
                      <h3>Steps</h3>
                      <p>Average steps over the whole day is 1000 days</p>
                    </div>
                    <h5>
                      1000 <span>Steps</span>
                    </h5>
                  </div>
                  <div className="chart-box">
                    <BarChart
                      borderRadius={100}
                      width={500}
                      height={300}
                      barWidth={30}
                      series={[
                        { data: cData, id: "pvId", color: "#FAA600" },
                        // { data: uData, id: 'uvId' },
                      ]}
                      xAxis={[{ data: wLabels, scaleType: "band" }]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt_20">
              <div className="row g-3">
                <div className="col-md-5">
                  <div className="card-dsgn heart-rate h-100">
                    <div className="heart-rate-top d-flex align-items-center justify-content-between">
                      <img src="/images/sleep-frame.svg" alt="" />
                      <div className="input-field">
                        <select>
                          <option value="">Today</option>
                        </select>
                      </div>
                    </div>
                    <div className="heart-inner d-flex align-items-center justify-content-between">
                      <div>
                        <h3>Sleep Activity</h3>
                        <p>
                          Average sleep activity over the whole day is 8 hours
                        </p>
                      </div>
                      <h5>
                        9 <span>Hours</span>
                      </h5>
                    </div>
                    <div className="container">
                      <div className="sleep-circle mt-5 d-flex justify-content-center align-item-center">
                        <CircularProgressbarWithChildren
                          value={70}
                          className="position-relative"
                        >
                          {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                          <img src="/images/sleep-circle.svg" alt="" />
                          <div
                            style={{
                              fontSize: 28,
                              marginTop: 0,
                              position: "absolute",
                              color: "#5BA559",
                            }}
                          >
                            <strong>9h 1m</strong>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="card-dsgn heart-rate">
                    <div className="heart-rate-top d-flex align-items-center justify-content-between">
                      <img src="/images/blood-pressure.svg" alt="" />
                      <div className="input-field">
                        <select>
                          <option value="">Today</option>
                        </select>
                      </div>
                    </div>
                    <div className="heart-inner d-flex align-items-center justify-content-between">
                      <div>
                        <h3>Blood Pressure</h3>
                        <p>Average Blood Pressure of the day</p>
                      </div>
                      <h5>
                        180/120 <span>mmHg</span>
                      </h5>
                    </div>
                    <div className="chart-box">
                      <BarChart
                        borderRadius={100}
                        width={500}
                        height={300}
                        series={[
                          { data: pData, id: "pvId", color: "#FAA600" },
                          { data: uData, id: "uvId", color: "#1D4D86" },
                        ]}
                        xAxis={[{ data: xLabels, scaleType: "band" }]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt_20">
              <div className="row g-3">
                <div className="col-md-7">
                  <div className="card-dsgn heart-rate">
                    <div className="heart-rate-top d-flex align-items-center justify-content-between">
                      <img src="/images/glucose-m.svg" alt="" />
                      <div className="input-field">
                        <select>
                          <option value="">Month</option>
                        </select>
                      </div>
                    </div>
                    <div className="heart-inner d-flex align-items-center justify-content-between">
                      <div>
                        <h3>Glucose Level</h3>
                        <p>Average Glucose level</p>
                      </div>
                      <h5>70-90</h5>
                    </div>
                    <div className="chart-box">
                      <BarChart
                        borderRadius={100}
                        width={500}
                        height={300}
                        series={[
                          { data: gData, id: "pvId", color: "#FAA600" },
                          { data: bData, id: "uvId", color: "#FEDFA8" },
                        ]}
                        xAxis={[{ data: aLabels, scaleType: "band" }]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card-dsgn heart-rate h-100">
                    <div className="heart-rate-top d-flex align-items-center justify-content-between">
                      <img src="/images/bmi.svg" alt="" />
                      <div className="input-field">
                        <select>
                          <option value="">Today</option>
                        </select>
                      </div>
                    </div>
                    <div className="heart-inner d-flex align-items-center justify-content-between">
                      <div>
                        <h3>Body mass Index</h3>
                        <p>Average BMI</p>
                      </div>
                      <h5>
                        55 <span>kg</span>
                      </h5>
                    </div>
                    <div className="chart-box">
                      <BarChart
                        borderRadius={100}
                        width={500}
                        height={300}
                        series={[
                          { data: gData, id: "pvId", color: "#FAA600" },
                          { data: bData, id: "uvId", color: "#FEDFA8" },
                        ]}
                        xAxis={[{ data: aLabels, scaleType: "band" }]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
