import React,{useState} from "react";
import Loading from "./Loading";
function SaveButton({loading,setLoading}){

    return(
        <>
             <button type="submit"  className='{`${loading ? "disabled" : ""}`}'>
                                                {
                                                    loading ? (
                                                    <>
                                                        <Loading />
                                                    </>
                                                    ):"Save & Continue"
                                                }
                                                </button>
        </>
    )
}
export default SaveButton