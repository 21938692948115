import React from 'react'
import './PlusButton.css'

export default function PlusButton(props) {
  return (
    <>
     <div className='plus-btn-box'>
        <button><i class="fa-light fa-plus"></i> {props.plusBtnName}</button>
     </div>
    </>
  )
}
