import React from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css'

export default function MyCalendar() {
  return (
   <>
    <div className='calendar ds-cd  mtsm-t'>
     <Calendar/>
    </div>
   </>
  )
}
