import React from "react";
import "./DietNutrition.css";
import FamilyDashboard from "../Dashboard/Dashboard";
import { BarChart } from '@mui/x-charts';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const pData = [
  900, 1200, 950, 900, 900, 900, 980, 1210, 1110, 700, 900, 900, 1300,];

const xLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function DietNutrition() {
  return (
    <>
      <FamilyDashboard />
      <div className="main-content diet-nutrition-box">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="row">
              <div className="page-header d-flex justify-content-between align-items-baseline">
                <h2>Nutrition, Today</h2>
                <div className="diet-dropdown-top input-field">
                  <select>
                    <option value="">Today</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="diet-time">
              <div className="row g-3">
                <div className="col-md-4">
                  <div className="diet-time-one">
                    <div className="diet-time-top d-flex justify-content-between align-items-center">
                      <h3>Breakfast</h3>
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>359 of 475 Cal</p>
                        <button>
                          <i className="fa-light fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="diet-info d-flex justify-content-between align-items-center">
                      <div className="left-diet-info">
                        <h4>Boiled Eggs</h4>
                        <p>1.0 cup (chopped)</p>
                      </div>
                      <div className="right-diet-info d-flex justify-content-between align-items-center gap-3">
                        <p>211 Cal</p>
                        <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                      </div>
                    </div>
                    <div className="diet-info d-flex justify-content-between align-items-center">
                      <div className="left-diet-info">
                        <h4>Brown Bread</h4>
                        <p>1.0 cup (chopped)</p>
                      </div>
                      <div className="right-diet-info d-flex justify-content-between align-items-center gap-3">
                        <p>148 Cal</p>
                        <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="diet-time-one">
                    <div className="diet-time-top d-flex justify-content-between align-items-center">
                      <h3>Lunch</h3>
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>0 of 475 Cal</p>
                        <button>
                          <i className="fa-light fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="diet-reminder">
                      <p>Reminder to have Lunch at 1:30 PM</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="diet-time-one">
                    <div className="diet-time-top d-flex justify-content-between align-items-center">
                      <h3>Dinner</h3>
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>0 of 475 Cal</p>
                        <button>
                          <i className="fa-light fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="diet-reminder">
                      <p>Reminder to have Dinner at 8:00 PM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="calorie-weekly-box mt_20">
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="calorie-card">
                    <h3>Your Calorie Budget</h3>
                    <div className="calorie-meals d-flex gap-3 align-items-start">
                      <CircularProgressbarWithChildren value={27}>
                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                        <img src="/images/fork-knife.svg" alt="" />
                      </CircularProgressbarWithChildren>
                      <div>
                        <h4>360/1,900</h4>
                        <p>
                          You haven’t tracked all meals today. Continue tracking
                          the rest of your meals and stick to your calorie
                          budget for the day.
                        </p>
                      </div>
                    </div>
                    <div className="diet-breakup mt_20">
                      <h4>Macronutrients Breakup</h4>
                      <div className="bean-main gap-2 align-items-center mt_20">
                        <div className="bean-box d-flex gap-2 align-items-center">
                          <img src="/images/bean.svg" alt="" />
                          <h5>Protiens</h5>
                        </div>
                        <div className="progress-box">
                          <p>22g/95g</p>
                          <div
                            className="progress"
                            role="progressbar"
                            aria-label="Info example"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar bg-info"
                              style={{ width: "23%" }}
                            ></div>
                          </div>
                          <span className="progress-number">23%</span>
                        </div>
                      </div>
                      <div className="bean-main gap-2 align-items-center mt_20">
                        <div className="bean-box d-flex gap-2 align-items-center">
                          <img src="/images/bean.svg" alt="" />
                          <h5>Fats</h5>
                        </div>
                        <div className="progress-box">
                          <p>22g/95g</p>
                          <div
                            className="progress"
                            role="progressbar"
                            aria-label="Info example"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar bg-info"
                              style={{ width: "35%" }}
                            ></div>
                          </div>
                          <span className="progress-number">23%</span>
                        </div>
                      </div>
                      <div className="bean-main gap-2 align-items-center mt_20">
                        <div className="bean-box d-flex gap-2 align-items-center">
                          <img src="/images/bean.svg" alt="" />
                          <h5>Carbs</h5>
                        </div>
                        <div className="progress-box">
                          <p>22g/95g</p>
                          <div
                            className="progress"
                            role="progressbar"
                            aria-label="Info example"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar bg-info"
                              style={{ width: "6%" }}
                            ></div>
                          </div>
                          <span className="progress-number">23%</span>
                        </div>
                      </div>
                      <div className="bean-main gap-2 align-items-center mt_20">
                        <div className="bean-box d-flex gap-2 align-items-center">
                          <img src="/images/bean.svg" alt="" />
                          <h5>Fiber</h5>
                        </div>
                        <div className="progress-box">
                          <p>22g/95g</p>
                          <div
                            className="progress"
                            role="progressbar"
                            aria-label="Info example"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar bg-info"
                              style={{ width: "15%" }}
                            ></div>
                          </div>
                          <span className="progress-number">23%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="weekly-trends-main">
                    <div className="weekly-trends-top">
                      <h3>Weekly Trends</h3>
                      <p>
                        See your weekly calorie and macro (protein, fat, carbs
                        and fibre) intake here. For an accurate analysis, track
                        your breakfast, lunch and dinner everyday
                      </p>
                    </div>
                    <div className="weekly-trends-chart mt_20">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Last 7 days</h4>
                        <div className="input-field ">
                          <select>
                            <option value="">Calories</option>
                          </select>
                        </div>
                      </div>
                      <div className="cal-box mt_20 d-flex gap-5 align-items-center">
                        <div>
                          <h5>359 Cal</h5>
                          <p>Weekly Total</p>
                        </div>
                        <div>
                          <h5>52 Cal</h5>
                          <p>Average Per Day</p>
                        </div>
                      </div>
                      <div className="weekly-trends-chart-box">
                        <div className="chart-box">
                          <BarChart
                            borderRadius={100}
                            width={500}
                            height={300}
                            barWidth={30} 
                            series={[
                              { data: pData, id: "pvId", color: "#FAA600"},
                              // { data: uData, id: 'uvId' },
                            ]}
                            xAxis={[{ data: xLabels, scaleType: "band" }]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
