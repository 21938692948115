import React, { useState } from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";
function DetailsMedicalHistory({setTab,setRecipientUserId,recipientUserId}){
    const auth = JSON.parse(localStorage.getItem("user"));
    const [pcpFullName,setPcpFullName] = useState("");
    const [pcpNumber,setPcpNumber] = useState("");
    const [pcpEmail,setPcpEmail] = useState("");
    const [pcpAddress,setPcpAddress] = useState("");
    const [advanced_directive_hasDND,SetAdvanced_directive_hasDND] = useState("");
    const [advanced_directive_hasLivingWill,SetAdvanced_directive_hasLivingWill] = useState("");
    const [advanced_directive_HasMedicalPOA,SetAdvanced_directive_HasMedicalPOA] = useState("");
    const [advanced_directive_fullName,SetAdvanced_directive_fullName] = useState("");
    const [advanced_directive_relationship,SetAdvanced_directive_relationship] = useState("");
    const [advanced_directive_contactInfo,SetAdvanced_directive_contactInfo] = useState("");
    const [assistive_devices_DeviceName,setAssistive_devices_DeviceName] = useState("");
    const [assistive_devices_hasTaken,setAssistive_devices_hasTaken] = useState("");
    const [vacH1,setVacH1] = useState("")
    const [vacH2,setVacH2] = useState("")
    const [vacH3,setVacH3] = useState("")
    const [vacH4,setVacH4] = useState("")
    const [vacHL1,setVacHL1] = useState("")
    const [vacHL2,setVacHL2] = useState("")
    const [vacHL3,setVacHL3] = useState("")
    const [vacHL4,setVacHL4] = useState("")
    const [vacHN1,setVacHN1] = useState("Covid 19 Vaccination")
    const [vacHN2,setVacHN2] = useState("Influenza (Flu)")
    const [vacHN3,setVacHN3] = useState("Pneumonia")
    const [vacHN4,setVacHN4] = useState("Tetanus")
    const [loading,setLoading] = useState(false)

    // Section Two
    const [secTwo, setSecTwo] = useState([{ healthcare_providers_name: "",healthcare_providers_speciality:"", healthcare_providers_phone : "",healthcare_providers_address:""}])
    let addFieldsSecTwo = () => {
        setSecTwo([...secTwo, { healthcare_providers_name: "",healthcare_providers_speciality:"", healthcare_providers_phone : "",healthcare_providers_address:""}])
    }
    let handleDeleteSecTwo = (i)=>{
        const delSecTwo = [...secTwo];
        delSecTwo.splice(i,1)
        setSecTwo(delSecTwo)
    }
    let handleChangeSecTwo = (i, e) => {
        let newFormValuesTwo = [...secTwo];
        newFormValuesTwo[i][e.target.name] = e.target.value;
        setSecTwo(newFormValuesTwo);
    }

    // Section Three 
    const [secThree, setSecThree] = useState([{ medical_diseases_condition: "",medical_diseases_dateDiagnosed:"", medical_diseases_ongoingTreatment : "",medical_diseases_medication:""}])
    let addFieldsSecThree = () => {
        setSecThree([...secThree, { medical_diseases_condition: "",medical_diseases_dateDiagnosed:"", medical_diseases_ongoingTreatment : "",medical_diseases_medication:""}])
    }
    let handleDeleteSecThree = (i)=>{
        const delSecThree = [...secThree];
        delSecThree.splice(i,1)
        setSecThree(delSecThree)
    }
    let handleChangeSecThree = (i, e) => {
        let newFormValuesThree = [...secThree];
        newFormValuesThree[i][e.target.name] = e.target.value;
        setSecThree(newFormValuesThree);
    }

    // Section Four 
    const [secFour, setSecFour] = useState([{ surgical_history_surgery : "",surgical_history_date :"", surgical_history_complication : ""}])
    let addFieldsSecFour = () => {
        setSecFour([...secFour, { surgical_history_surgery : "",surgical_history_date :"", surgical_history_complication : ""}])
    }
    let handleDeleteSecFour = (i)=>{
        const delSecFour = [...secFour];
        delSecFour.splice(i,1)
        setSecFour(delSecFour)
    }
    let handleChangeSecFour = (i, e) => {
        let newFormValuesFour = [...secFour];
        newFormValuesFour[i][e.target.name] = e.target.value;
        setSecFour(newFormValuesFour);
    }

     // Section Five 
     const [secFive, setSecFive] = useState([{ allergies_medication : "",allergies_food :"", allergies_otherDetails : ""}])
     let addFieldsSecFive = () => {
         setSecFive([...secFive, { allergies_medication : "",allergies_food :"", allergies_otherDetails : ""}])
     }
     let handleDeleteSecFive = (i)=>{
         const delSecFive = [...secFive];
         delSecFive.splice(i,1)
         setSecFive(delSecFive)
     }
     let handleChangeSecFive = (i, e) => {
         let newFormValuesFive = [...secFive];
         newFormValuesFive[i][e.target.name] = e.target.value;
         setSecFive(newFormValuesFive);
     }

     // Section Six
     const [secSix, setSecSix] = useState([{ current_medications_medication : "",current_medications_dosage :"", current_medications_frequency : "",current_medications_purpose : ""}])
     let addFieldsSecSix = () => {
        setSecSix([...secSix, { current_medications_medication : "",current_medications_dosage :"", current_medications_frequency : "",current_medications_purpose : ""}])
     }
     let handleDeleteSecSix = (i)=>{
         const delSecSix = [...secSix];
         delSecSix.splice(i,1)
         setSecSix(delSecSix)
     }
     let handleChangeSecSix = (i, e) => {
         let newFormValuesSix = [...secSix];
         newFormValuesSix[i][e.target.name] = e.target.value;
         setSecSix(newFormValuesSix);
     }

     // Section Eight
     const [secEight, setSecEight] = useState([{ other_treatment_therapy_treatment : "",other_treatment_therapy_therapy :"", other_treatment_therapy_frequency : "",other_treatment_therapy_duration : "",other_treatment_therapy_additionalNotes:""}])
     let addFieldsSecEight = () => {
        setSecEight([...secEight, { other_treatment_therapy_treatment : "",other_treatment_therapy_therapy :"", other_treatment_therapy_frequency : "",other_treatment_therapy_duration : "",other_treatment_therapy_additionalNotes:""}])
     }
     
     let handleDeleteSecEight = (i)=>{
         const delSecEight = [...secEight];
         delSecEight.splice(i,1)
         setSecEight(delSecEight)
     }
     let handleChangeSecEight = (i, e) => {
         let newFormValuesEight = [...secEight];
         newFormValuesEight[i][e.target.name] = e.target.value;
         setSecEight(newFormValuesEight);
     }

    const medicalHistoryStore = async (e)=>{
        e.preventDefault();
        var formdata = new FormData();

        formdata.append("recipientUserId",recipientUserId);
        formdata.append("pcpFullName", pcpFullName);
        formdata.append("pcpNumber", pcpNumber);
        formdata.append("pcpEmail", pcpEmail);
        formdata.append("pcpAddress", pcpAddress);
       
        secTwo.map((item)=>{
            if(item.healthcare_providers_name != ""){
                formdata.append("healthcare_providers_name[]", item.healthcare_providers_name);
            }else{
                formdata.append("healthcare_providers_name[]", "");
            }
            if(item.healthcare_providers_speciality != ""){
                formdata.append("healthcare_providers_speciality[]", item.healthcare_providers_speciality);
            }else{
                formdata.append("healthcare_providers_speciality[]", "");
            }
            if(item.healthcare_providers_phone != ""){
                formdata.append("healthcare_providers_phone[]", item.healthcare_providers_phone);
            }else{
                formdata.append("healthcare_providers_phone[]", "");
            }
            if(item.healthcare_providers_address != ""){
                formdata.append("healthcare_providers_address[]", item.healthcare_providers_address);
            }else{
                formdata.append("healthcare_providers_address[]", "");
            }
        })
       
        
        secThree.map((item)=>{
            if(item.medical_diseases_condition != ""){
                formdata.append("medical_diseases_condition[]", item.medical_diseases_condition);
            }else{
                formdata.append("medical_diseases_condition[]", "");
            }
            if(item.medical_diseases_dateDiagnosed != ""){
                formdata.append("medical_diseases_dateDiagnosed[]", item.medical_diseases_dateDiagnosed);
            }else{
                formdata.append("medical_diseases_dateDiagnosed[]", "");
            }
            if(item.medical_diseases_ongoingTreatment != ""){
                formdata.append("medical_diseases_ongoingTreatment[]", item.medical_diseases_ongoingTreatment);
            }else{
                formdata.append("medical_diseases_ongoingTreatment[]", "");
            }
            if(item.medical_diseases_medication != ""){
                formdata.append("medical_diseases_medication[]", item.medical_diseases_medication);
            }else{
                formdata.append("medical_diseases_medication[]", "");
            }
        })
        secFour.map((item)=>{
            if(item.surgical_history_surgery != ""){
                formdata.append("surgical_history_surgery[]", item.surgical_history_surgery);
            }else{
                formdata.append("surgical_history_surgery[]", "");
            }
            if(item.surgical_history_date != ""){
                formdata.append("surgical_history_date[]", item.surgical_history_date);
            }else{
                formdata.append("surgical_history_date[]", "");
            }
            if(item.surgical_history_complication != ""){
                formdata.append("surgical_history_complication[]", item.surgical_history_complication);
            }else{
                formdata.append("surgical_history_complication[]", "");
            }
           
        })
        secFive.map((item)=>{
            if(item.allergies_medication != ""){
                formdata.append("allergies_medication[]", item.allergies_medication);
            }else{
                formdata.append("allergies_medication[]", "");
            }
            if(item.allergies_food != ""){
                formdata.append("allergies_food[]", item.allergies_food);
            }else{
                formdata.append("allergies_food[]", ""); 
            }
            if(item.allergies_otherDetails != ""){
                formdata.append("allergies_otherDetails[]", item.allergies_otherDetails);
            }else{
                formdata.append("allergies_otherDetails[]", "");
            }
        })
        secSix.map((item)=>{
            if(item.current_medications_medication != ""){
                formdata.append("current_medications_medication[]", item.current_medications_medication);
            }
            else{
                formdata.append("current_medications_medication[]", "");
            }
            if(item.current_medications_dosage != ""){
                formdata.append("current_medications_dosage[]", item.current_medications_dosage);
            }else{
                formdata.append("current_medications_dosage[]", "");
            } 
            if(item.current_medications_frequency != ""){
                formdata.append("current_medications_frequency[]", item.current_medications_frequency);
            }else{
                formdata.append("current_medications_frequency[]", ""); 
            }
            if(item.current_medications_purpose != ""){
                formdata.append("current_medications_purpose[]", item.current_medications_purpose);
            }else{
                formdata.append("current_medications_purpose[]", ""); 
            }
        })
        const vhhasToken = [];
        vhhasToken.push(vacH1)
        vhhasToken.push(vacH2)
        vhhasToken.push(vacH3)
        vhhasToken.push(vacH4)
        vhhasToken.map((item)=>{
            formdata.append("vacination_history_hasTaken[]", item);
        })

        const vhhasDos = [];
        vhhasDos.push(vacHL1)
        vhhasDos.push(vacHL2)
        vhhasDos.push(vacHL3)
        vhhasDos.push(vacHL4)
        vhhasDos.map((item)=>{
            formdata.append("vacination_history_lastDoseDate[]", item);
        })

        const vhhasName = [];
        vhhasName.push(vacHN1)
        vhhasName.push(vacHN2)
        vhhasName.push(vacHN3)
        vhhasName.push(vacHN4)
        vhhasName.map((item)=>{
            formdata.append("vacination_history_vacineName[]", item);
        })
              
        secEight.map((item)=>{
            if(item.other_treatment_therapy_treatment != ""){
                formdata.append("other_treatment_therapy_treatment[]", item.other_treatment_therapy_treatment);
            }else{
                formdata.append("other_treatment_therapy_treatment[]", "");
            }
            if(item.other_treatment_therapy_therapy != ""){
                formdata.append("other_treatment_therapy_therapy[]", item.other_treatment_therapy_therapy);
            }else{
                formdata.append("other_treatment_therapy_therapy[]", "");
            }
            if(item.other_treatment_therapy_frequency != ""){
                formdata.append("other_treatment_therapy_frequency[]", item.other_treatment_therapy_frequency);
            }else{
                formdata.append("other_treatment_therapy_frequency[]", "");
            }
            if(item.other_treatment_therapy_duration != ""){
                formdata.append("other_treatment_therapy_duration[]", item.other_treatment_therapy_duration);
            }else{
                formdata.append("other_treatment_therapy_duration[]", ""); 
            }
            if(item.other_treatment_therapy_additionalNotes != ""){
                formdata.append("other_treatment_therapy_additionalNotes[]", item.other_treatment_therapy_additionalNotes);
            }else{
                formdata.append("other_treatment_therapy_additionalNotes[]", "");
            }
        })
        formdata.append("assistive_devices_DeviceName[]", assistive_devices_hasTaken);
        formdata.append("assistive_devices_hasTaken[]", assistive_devices_hasTaken);
       
        formdata.append("advanced_directive_hasDND", advanced_directive_hasDND);
        formdata.append("advanced_directive_hasLivingWill", advanced_directive_hasLivingWill);
        formdata.append("advanced_directive_HasMedicalPOA", advanced_directive_HasMedicalPOA);
        formdata.append("advanced_directive_fullName", advanced_directive_fullName);
        formdata.append("advanced_directive_relationship", advanced_directive_relationship);
        formdata.append("advanced_directive_contactInfo", advanced_directive_contactInfo);
        setLoading(true)
        const apicall = await LoginService.DetailsMedicalHistory(auth,formdata)
        if(apicall.state === true){
            apicall.result.json().then((resp)=>{
                setTab(resp.tab)
                setLoading(false)
            })
        }else{
            apicall.result.json().then((resp)=>{
                console.log("failed");
                setLoading(false)
            })
        }
    }
    return(
        <>
         <h5>Detailed Medical History</h5>
         <form onSubmit={medicalHistoryStore}>
                                        <div className="forms">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ul>
                                                        <li>Primary Care Physician (PCP) Information</li>
                                                    </ul>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Full Name</label>
                                                                <input type="text" placeholder="value" value={pcpFullName} onChange={(e)=>setPcpFullName(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Phone Number</label>
                                                                <input type="text" placeholder="value" value={pcpNumber} onChange={(e)=>setPcpNumber(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Email Id</label>
                                                                <input type="text" placeholder="value" value={pcpEmail} onChange={(e)=>setPcpEmail(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-field">
                                                                <label for="">Clinic Address</label>
                                                                <input type="text" placeholder="value" value={pcpAddress} onChange={(e)=>setPcpAddress(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Specialists and Other Healthcare Providers</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <a href="javascript:void(0)" onClick={()=>addFieldsSecTwo()}>+ Add</a>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Name</th>
                                                                    <th>Speciality</th>
                                                                    <th>Phone number</th>
                                                                    <th>Address</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                
                                                                {
                                                                    secTwo.map((element,index)=>(
                                                                        <>
                                                                            <tr>
                                                                                <td>{index+1}</td>
                                                                                <td>
                                                                                    <div className="input-field">
                                                                                        <input type="text" placeholder="value" name="healthcare_providers_name" value={element.healthcare_providers_name || ""} onChange={e=>handleChangeSecTwo(index,e)}/>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-field">
                                                                                        <input type="text" placeholder="value" name="healthcare_providers_speciality" value={element.healthcare_providers_speciality || ""} onChange={e=>handleChangeSecTwo(index,e)}/>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-field">
                                                                                        <input type="text" placeholder="value" name="healthcare_providers_phone" value={element.healthcare_providers_phone || ""} onChange={e=>handleChangeSecTwo(index,e)}/>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-field">
                                                                                        <input type="text" placeholder="value" name="healthcare_providers_address" value={element.healthcare_providers_address || ""} onChange={e=>handleChangeSecTwo(index,e)}/>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {/* <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button> */}
                                                                                    <button onClick={()=>handleDeleteSecTwo(index)} class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                                
                                                            </tbody>

                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig headig-two">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Medical Conditions/Chronic Diseases:</li>
                                                            </ul>
                                                            <span>List all current diagnoses and the date of diagnosis (e.g.,
                                                                Diabetes, 2010):</span>
                                                        </div>
                                                        <div className="add-button">
                                                            <a href="javascript:void(0)" onClick={()=>addFieldsSecThree()}>+ Add Conditions</a>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Conditions</th>
                                                                    <th>Date Diagnosed</th>
                                                                    <th>Ongoing Treatment</th>
                                                                    <th>Medication</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                               
                                                                {
                                                                    secThree.map((element,index)=>(<>
                                                                       <tr>
                                                                            <td>{index+1}</td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="medical_diseases_condition" value={element.medical_diseases_condition || ""} onChange={e=>handleChangeSecThree(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="medical_diseases_dateDiagnosed" value={element.medical_diseases_dateDiagnosed || ""} onChange={e=>handleChangeSecThree(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="medical_diseases_ongoingTreatment" value={element.medical_diseases_ongoingTreatment || ""} onChange={e=>handleChangeSecThree(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="medical_diseases_medication" value={element.medical_diseases_medication || ""} onChange={e=>handleChangeSecThree(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {/* <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button> */}
                                                                                <button onClick={()=>handleDeleteSecThree(index)} class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                            </td>
                                                                        </tr> 
                                                                    </>))
                                                                }
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Surgical History (Include dates and complications if any)</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <a href="javascript:void(0)" onClick={()=>addFieldsSecFour()}>+ Add</a>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Surgery</th>
                                                                    <th>Date</th>
                                                                    <th>Complications</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                              
                                                                {
                                                                    secFour.map((element,index)=>(
                                                                        <>
                                                                             <tr>
                                                                                <td>{index+1}</td>
                                                                                <td>
                                                                                    <div className="input-field">
                                                                                        <input type="text" placeholder="value" name="surgical_history_surgery" value={element.surgical_history_surgery || ""} onChange={e=>handleChangeSecFour(index,e)}/>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-field">
                                                                                        <input type="text" placeholder="value" name="surgical_history_date" value={element.surgical_history_date || ""} onChange={e=>handleChangeSecFour(index,e)}/>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="input-field">
                                                                                        <input type="text" placeholder="value" name="surgical_history_complication" value={element.surgical_history_complication || ""} onChange={e=>handleChangeSecFour(index,e)}/>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {/* <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button> */}
                                                                                    <button class="btn btn-sm action" onClick={()=>handleDeleteSecFour(index)}><img src="/images/trash.svg" alt="" /></button>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                               

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Allergies (List and describe reactions)</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <a href="javascript:void(0)" onClick={()=>addFieldsSecFive()}>+ Add</a>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Medication</th>
                                                                    <th>Food</th>
                                                                    <th>Other</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    secFive.map((element,index)=>(
                                                                        <>
                                                                         <tr>
                                                                            <td>{index+1}</td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="allergies_medication" value={element.allergies_medication || ""} onChange={e=>handleChangeSecFive(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="allergies_food" value={element.allergies_food || ""} onChange={e=>handleChangeSecFive(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="allergies_otherDetails" value={element.allergies_otherDetails || ""} onChange={e=>handleChangeSecFive(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {/* <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button> */}
                                                                                <button onClick={()=>handleDeleteSecFive(index)} class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                            </td>
                                                                        </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                               

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Current Medications</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <a href="javascript:void(0)" onClick={()=>addFieldsSecSix()}>+ Add</a>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist table-responsive ">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Medication</th>
                                                                    <th>Dosage</th>
                                                                    <th>Frequency</th>
                                                                    <th>Purpose</th>
                                                                    <th>Acion</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                               
                                                                {
                                                                    secSix.map((element,index)=>(
                                                                        <>
                                                                        <tr>
                                                                            <td>{index+1}</td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="current_medications_medication" value={element.current_medications_medication || ""} onChange={e=>handleChangeSecSix(index,e)} />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="current_medications_dosage" value={element.current_medications_dosage || ""} onChange={e=>handleChangeSecSix(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="current_medications_frequency" value={element.current_medications_frequency || ""} onChange={e=>handleChangeSecSix(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="current_medications_purpose" value={element.current_medications_purpose || ""} onChange={e=>handleChangeSecSix(index,e)}/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {/* <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button> */}
                                                                                <button onClick={()=>handleDeleteSecSix(index)} class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                            </td>
                                                                        </tr>
                                                                        </>
                                                                    ))
                                                                }


                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="vaccination-history">
                                                        <h5>Vaccination History</h5>
                                                        <div className="vaccination-item">
                                                            <div className="vaccination">
                                                                <span>1. Covid 19 Vaccination</span>
                                                                <div className="options">
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="covid19" value="yes" checked={vacH1 === "1"} onChange={()=>setVacH1("1")} />
                                                                        Yes
                                                                    </label>
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="covid19" value="no" checked={vacH1 === "0"} onChange={()=>setVacH1("0")}/>
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <div className="last-dose">
                                                                    <strong>Last Dose:</strong> <input type="text"
                                                                        name="covid19_last_dose" value={vacHL1} onChange={(e)=>setVacHL1(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="vaccination">
                                                                <span>2. Influenza (Flu)</span>
                                                                <div className="options">
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="flu" value="yes" checked={vacH2 === "1"} onChange={()=>setVacH2("1")} />
                                                                        Yes
                                                                    </label>
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="flu" value="no" checked={vacH2 === "0"} onChange={()=>setVacH2("0")}/>
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <div className="last-dose">
                                                                    <strong>Last Dose:</strong> <input type="text"
                                                                        name="flu_last_dose" value={vacHL2} onChange={(e)=>setVacHL2(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="vaccination">
                                                                <span>3. Pneumonia</span>
                                                                <div className="options">
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="pneumonia" value="yes" checked={vacH3 === "1"} onChange={()=>setVacH3("1")}/>
                                                                        Yes
                                                                    </label>
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="pneumonia" value="no"  checked={vacH3 === "0"} onChange={()=>setVacH3("0")}/>
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <div className="last-dose">
                                                                    <strong>Last Dose:</strong> <input type="text"
                                                                        name="pneumonia_last_dose" value={vacHL3} onChange={(e)=>setVacHL3(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="vaccination">
                                                                <span>4. Tetanus</span>
                                                                <div className="options">
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="tetanus" value="yes"  checked={vacH4 === "1"} onChange={()=>setVacH4("1")}/>
                                                                        Yes
                                                                    </label>
                                                                    <label>
                                                                        <input className="form-check-input me-1" type="checkbox" name="tetanus" value="no" checked={vacH4 === "0"} onChange={()=>setVacH4("0")} />
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <div className="last-dose">
                                                                    <strong>Last Dose:</strong> <input type="text"
                                                                        name="tetanus_last_dose" value={vacHL4} onChange={(e)=>setVacHL4(e.target.value)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="headig">
                                                        <div className="heading-txt">
                                                            <ul>
                                                                <li>Other Treatments or Therapies</li>
                                                            </ul>
                                                        </div>
                                                        <div className="add-button">
                                                            <a href="javascript:void(0)" onClick={()=>addFieldsSecEight()}>+ Add</a>
                                                        </div>
                                                    </div>
                                                    <div className="Specialist">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Sn.</th>
                                                                    <th>Treatment</th>
                                                                    <th>Therapy</th>
                                                                    <th>Frequency</th>
                                                                    <th>Duration</th>
                                                                    <th>Additional Notes</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                               
                                                                {
                                                                    secEight.map((element,index)=>(
                                                                        
                                                                        <>
                                                                         <tr>
                                                                            <td>{index+1}</td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="other_treatment_therapy_treatment" value={element.other_treatment_therapy_treatment || ""} onChange={e=>handleChangeSecEight(index,e)}  />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="other_treatment_therapy_therapy" value={element.other_treatment_therapy_therapy || ""} onChange={e=>handleChangeSecEight(index,e)}  />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="other_treatment_therapy_frequency" value={element.other_treatment_therapy_frequency || ""} onChange={e=>handleChangeSecEight(index,e)} />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="other_treatment_therapy_duration" value={element.other_treatment_therapy_duration || ""} onChange={e=>handleChangeSecEight(index,e)} />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-field">
                                                                                    <input type="text" placeholder="value" name="other_treatment_therapy_additionalNotes" value={element.other_treatment_therapy_additionalNotes || ""} onChange={e=>handleChangeSecEight(index,e)} />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {/* <button class="btn btn-sm action"><img src="/images/edit-2.svg" alt="" /></button> */}
                                                                                <button onClick={()=>handleDeleteSecEight(index)} class="btn btn-sm action"><img src="/images/trash.svg" alt="" /></button>
                                                                            </td>

                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                               


                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <ul>
                                                        <li>Other Treatments or Therapies</li>
                                                    </ul>
                                                    <div className="assistive-devices">

                                                        <ul>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="cane" checked={assistive_devices_hasTaken === "cane"} onChange={() => setAssistive_devices_hasTaken("cane")} />
                                                                    Cane
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="walker" checked={assistive_devices_hasTaken === "walker"} onChange={() => setAssistive_devices_hasTaken("walker")}/>
                                                                    Walker
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="wheelchair" checked={assistive_devices_hasTaken === "wheelchair"} onChange={() => setAssistive_devices_hasTaken("wheelchair")}/>
                                                                    Wheelchair
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="hearing-aid" checked={assistive_devices_hasTaken === "hearing-aid"} onChange={() => setAssistive_devices_hasTaken("hearing-aid")}/>
                                                                    Hearing Aid
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label>
                                                                    <input className="form-check-input" type="checkbox" name="device" value="glasses" checked={assistive_devices_hasTaken === "glasses"} onChange={() => setAssistive_devices_hasTaken("glasses")}/>
                                                                    Glasses
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <ul>
                                                        <li>Advanced Directives</li>
                                                    </ul>
                                                    <form>
                                                        <fieldset>
                                                            <div className="form-group">
                                                                <label for="dnr">1. Do you have a Do Not Resuscitate (DNR)
                                                                    order?</label>
                                                                <div className="checkbox">  
                                                                <input className="form-check-input" type="checkbox" id="dnr-yes" checked={advanced_directive_hasDND === "1"} onChange={() => SetAdvanced_directive_hasDND("1")} />
                                                                    <label className="form-check-label" for="dnr-yes">Yes</label>
                                                                    <input className='form-check-input' type="checkbox" id="dnr-no" checked={advanced_directive_hasDND === "0"} onChange={() => SetAdvanced_directive_hasDND("0")} />
                                                                    <label className="form-check-label" for="dnr-no">No</label>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label for="living-will">2. Living Will</label>
                                                                <div className="checkbox">
                                                                    <input className='form-check-input' type="checkbox" id="living-will-yes" checked={advanced_directive_hasLivingWill === "1"} onChange={() => SetAdvanced_directive_hasLivingWill("1")} />
                                                                    <label className='form-check-label' for="living-will-yes">Yes</label>
                                                                    <input className='form-check-input' type="checkbox" id="living-will-no" checked={advanced_directive_hasLivingWill === "0"} onChange={() => SetAdvanced_directive_hasLivingWill("0")}/>
                                                                    <label className='form-check-label' for="living-will-no">No</label>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label for="medical-power">3. Medical Power of Attorney</label>
                                                                <div className="checkbox">
                                                                    <input className='form-check-input' type="checkbox" id="medical-power-yes" checked={advanced_directive_HasMedicalPOA === "1"} onChange={() => SetAdvanced_directive_HasMedicalPOA("1")}/>
                                                                    <label className='form-check-label' for="medical-power-yes">Yes</label>
                                                                    <input className='form-check-input' type="checkbox" id="medical-power-no" checked={advanced_directive_HasMedicalPOA === "0"} onChange={() => SetAdvanced_directive_HasMedicalPOA("0")}/>
                                                                    <label className='form-check-label' for="medical-power-no">No</label>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </form>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="input-field">
                                                                <label for="">Full Name</label>
                                                                <input type="text" name="" id="" placeholder="value" value={advanced_directive_fullName} onChange={(e)=>SetAdvanced_directive_fullName(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="input-field">
                                                                <label for="">Relationship</label>
                                                                <input type="text" name="" id="" placeholder="value" value={advanced_directive_relationship} onChange={(e)=>SetAdvanced_directive_relationship(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="input-field" >
                                                                <label for="">Contact Info</label>
                                                                <input type="text" name="" id="" placeholder="value" value={advanced_directive_contactInfo} onChange={(e)=>SetAdvanced_directive_contactInfo(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <SaveButton setLoading={setLoading} loading={loading}/>
                                        </div>
         </form>
        </>
    )
}
export default DetailsMedicalHistory