import React from "react";
import "./Notes.css";

export default function Notes({
  noteHeading,
  aLink,
  btnType,
  contentHeadingOne,
  descriptionOne,
  contentHeadingTwo,
  descriptionTwo,
}) {
  return (
    <>
      <div className="notes-main">
        <div className="notes-top d-sm-flex align-items-center justify-content-between">
          <h3>{noteHeading}</h3>
          <div className="notes-links d-flex align-items-center gap-2">
            <a href="">{aLink}</a>
            <button>
              <i className="fa-light fa-plus"></i> {btnType}
            </button>
          </div>
        </div>
        <div className="note-info">
          <h3>{contentHeadingOne}</h3>
          <p>{descriptionOne}</p>
        </div>
        <div className="note-info">
          <h3>{contentHeadingTwo}</h3>
          <p>{descriptionTwo}</p>
        </div>
      </div>
    </>
  );
}
