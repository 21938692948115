import React,{useState} from "react";  
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";

function FinancialInformation({setTab,setRecipientUserId,recipientUserId}){
    const auth = JSON.parse(localStorage.getItem("user"));
    const [loading,setLoading] = useState(false)
    const [name,setname] = useState("")
    const [relationship,setrelationship] = useState("")
    const [phone,setphone] = useState("")
    const [email,setemail] = useState("")
    const [insurance_provider,setinsurance_provider] = useState("")
    const [policy_number,setpolicy_number] = useState("")
    const [coverage_details,setcoverage_details] = useState("")
    const [long_term_care,setlong_term_care] = useState("")
    const [provider_and_policy_number,setprovider_and_policy_number] = useState("")
    const FinancialStore = async (e)=>{
        e.preventDefault();
        var formData = new FormData();
        formData.append("recipientUserId",recipientUserId);
        formData.append("name",name);
        formData.append("relationship",relationship);
        formData.append("phone",phone);
        formData.append("email",email);
        formData.append("insurance_provider",insurance_provider);
        formData.append("policy_number",policy_number);
        formData.append("coverage_details",coverage_details);
        formData.append("long_term_care",long_term_care);
        formData.append("provider_and_policy_number",provider_and_policy_number);
            setLoading(true)
            const apicall = await LoginService.FinancialInfo(auth,formData)
            if(apicall.state === true){
                apicall.result.json().then((resp)=>{
                    setTab(resp.tab)
                    setLoading(false)
                })
            }else
            {
                apicall.result.json().then((resp)=>{
                    console.log("failed");
                    setLoading(false)
                })
            }
    }
    return(
        <>
        <h5>Social Preferences</h5>
                <div className="forms ps-0">
                      <form onSubmit={FinancialStore}>
                      <p>1. Responsible Party for Billing</p>
                      <div className="Responsible ps-4">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-field">
                              <label for="">Full Name</label>
                              <input type="text" placeholder="value" value={name} onChange={(e)=>setname(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label for="">Relationship to Resident</label>
                              <input type="text" placeholder="value" value={relationship} onChange={(e)=>setrelationship(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label for="">Phone Number</label>
                              <input type="text" placeholder="value" value={phone} onChange={(e)=>setphone(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-field">
                              <label for="">Email Address</label>
                              <input type="text" placeholder="value" value={email} onChange={(e)=>setemail(e.target.value)} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my-4">
                        <p>2. Health Insurance Information</p>
                        <ul className="d-flex ps-5">
                          <li className="">
                            <label className="form-label">
                              Insurance Provider:
                            </label>
                          </li>
                          <li className="list-group">
                            <div className="last-dose d-flex align-items-center">
                              <input type="text" className=" border-bottom-5" value={insurance_provider} onChange={(e)=>setinsurance_provider(e.target.value)} />
                            </div>
                          </li>
                        </ul>
                        <ul className="d-flex ps-5">
                          <li className="">
                            <label className="form-label">Policy Number:</label>
                          </li>
                          <li className="list-group w-50">
                            <div className="last-dose d-flex align-items-center">
                              <input type="text" className=" border-bottom-5" value={policy_number} onChange={(e)=>setpolicy_number(e.target.value)} />
                            </div>
                          </li>
                        </ul>
                        <ul className="d-flex ps-5">
                          <li className="">
                            <label className="form-label">
                              Coverage Details (Attach additional documents as
                              needed):
                            </label>
                          </li>
                          <li className="list-group w-50">
                            <div className="last-dose d-flex align-items-center">
                              <input type="text" className=" border-bottom-5" value={coverage_details} onChange={(e)=>setcoverage_details(e.target.value)} />
                            </div>
                          </li>
                        </ul>
                        <ul className="d-flex ps-5">
                          <li className="">
                            <label className="form-label">
                              Long-Term Care Insurance:
                            </label>
                          </li>
                          <li className="list-group w-50">
                            <div className="options mt-sm-0 mt-3 mx-3">
                              <label>
                                <input
                                  className="form-check-input me-1"
                                  type="checkbox"
                                  name="flu"
                                  value="yes"
                                  checked={long_term_care == '1'}
                                  onChange={(e)=>setlong_term_care('1')}
                                />
                                Yes
                              </label>
                              <label>
                                <input
                                  className="form-check-input me-1"
                                  type="checkbox"
                                  name="flu"
                                  value="no"
                                  checked={long_term_care == '0'}
                                  onChange={(e)=>setlong_term_care('0')}
                                />
                                No
                              </label>
                            </div>
                          </li>
                        </ul>
                        <ul className="d-flex ps-5">
                          <li className="">
                            <label className="form-label">
                              Provider and Policy Number:
                            </label>
                          </li>
                          <li className="list-group w-50">
                            <div className="last-dose d-flex align-items-center">
                              <input type="text" className=" border-bottom-5" value={provider_and_policy_number} onChange={(e)=>setprovider_and_policy_number(e.target.value)} />
                            </div>
                          </li>
                        </ul>
                      </div>
                      <SaveButton setLoading={setLoading} loading={loading}/>
                      </form>
                </div>
        </>
    )
}
export default FinancialInformation