import React,{useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginService from "../../../Service/Team/Login/LoginService";
import Loading from "../Loader/Loading";
import SaveButton from "../Loader/SaveButton";
export default function  PersonalInformation({setTab,setRecipientUserId,recipientUserId}) {
    const [fullName,setFullName] = useState("");
    const [nickName,setNickName] = useState("");
    const [dob,setDob] = useState("");
    const [age,setAge] = useState("");
    const [nationality,setNationality] = useState("");
    const [country,setCountry] = useState("");
    const [state,setState] = useState("");
    const [city,setCity] = useState("");
    const [gender,setGender] = useState("");
    const [ethnicity,setEthnicity] = useState("");
    const [religionFaith,setReligionFaith] = useState("");
    const [primaryLanguage,setPrimaryLanguage] = useState("");
    const [otherLanguage,setOtherLanguage] = useState("");
    const [educationLevel,setEducationLevel] = useState("");
    const [occupation,setOccupation] = useState("");
    const [maritalStatus,setMaritalStatus] = useState("");
    const [spouseName,setSpouseName] = useState("");
    const [isChildrenAdded,setIsChildrenAdded] = useState("");
    const [permanentAddress,setPermanentAddress] = useState("");
    const [currentAddress,setCurrentAddress] = useState("");
    const [contactPrimaryPhone,setContactPrimaryPhone] = useState("");
    const [contactSecondaryPhone,setContactSecondaryPhone] = useState("");
    const [contactEmail,setContactEmail] = useState("");
    const [isPhone,setIsPhone] = useState(false);
    const [isEmail,setIsEmail] = useState(false);
    const [isText,setIsText] = useState(false);
    const auth = JSON.parse(localStorage.getItem("user"));
    const [loading,setLoading] = useState(false)
    const [sameAsParmanent,setSameAsParmanent] = useState(false);
    const personalInfo = async (e)=>{
        e.preventDefault();
        if(validate()){
            setLoading(true)
            const item = {
                fullName:fullName,
                nickName:nickName,
                dob:dob,
                age:age,
                nationality:nationality,
                country:country,
                state:state,
                city:city,
                gender:gender,
                ethnicity:ethnicity,
                religionFaith:religionFaith,
                primaryLanguage:primaryLanguage,
                otherLanguage:otherLanguage,
                educationLevel:educationLevel,
                occupation:occupation,
                maritalStatus:maritalStatus,
                spouseName:spouseName,
                permanentAddress:permanentAddress,
                currentAddress:currentAddress,
                contactPrimaryPhone:contactPrimaryPhone,
                contactSecondaryPhone:contactSecondaryPhone,
                contactEmail:contactEmail,
                isPhone:isPhone,
                isEmail:isEmail,
                isText:isText,
                recipientUserId:recipientUserId
            }
          
            console.log(auth,item)
            const apicall = await LoginService.PersonalInfo(auth,item)
            if(apicall.state === true){
                apicall.result.json().then((resp)=>{
                    toast(resp.message)
                    setLoading(false)
                    setTab(resp.tab)
                    setRecipientUserId(resp.recipientUserTableId)
                })
            }else{
                apicall.result.json().then((resp)=>{
                    console.log("failed");
                    setLoading(false)
                })
            }
        }
    }
    const validate = ()=>{
        let result = true;
        if(fullName === ''){
            toast("Fullname field is required")
            result = false;
        }
        return result;
    }
    const sameParmanent = (e) =>{
        var checked = e.target.checked;
        if(checked === true){
            setCurrentAddress(permanentAddress);
            setSameAsParmanent(true)
        }else{
            setCurrentAddress(""); 
            setSameAsParmanent(false)
        }
    }
    const parmanentAddress = (e)=>{
        setPermanentAddress(e.target.value)
        if(sameAsParmanent === true){
            setCurrentAddress(e.target.value);
        }
    }
    return (
        <>
         <h5>Personal Information</h5>
           <form action="" onSubmit={personalInfo}>
           <div className="forms">
                <div className="row">
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Full Legal Name</label>
                            <input type="text" placeholder="value" value={fullName} onChange={(e)=>setFullName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Preferred Name/Nickname</label>
                            <input type="text" placeholder="value" value={nickName} onChange={(e)=>setNickName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Date of Birth</label>
                            <input type="date" placeholder="value" value={dob} onChange={(e)=>setDob(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Age</label>
                            <input type="text" placeholder="value" value={age} onChange={(e)=>setAge(e.target.value)}  />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">City</label>
                            <input type="text" placeholder="value" value={city} onChange={(e)=>setCity(e.target.value)}  />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">State</label>
                            <input type="text" placeholder="value" value={state} onChange={(e)=>setState(e.target.value)}  />

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Country</label>
                            <input type="text" placeholder="value" value={country} onChange={(e)=>setCountry(e.target.value)}  />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Gender</label>
                            <input type="text" placeholder="value" value={gender} onChange={(e)=>setGender(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Nationality</label>
                            <input type="text" placeholder="value" value={nationality} onChange={(e)=>setNationality(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Ethnicity</label>
                            <input type="text" placeholder="value" value={ethnicity} onChange={(e)=>setEthnicity(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Religion/Faith</label>
                            <input type="text" placeholder="value" value={religionFaith} onChange={(e)=>setReligionFaith(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Primary Language</label>
                            <input type="text" placeholder="value" value={primaryLanguage} onChange={(e)=>setPrimaryLanguage(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Other Languages Spoken</label>
                            <input type="text" placeholder="value" value={otherLanguage} onChange={(e)=>setOtherLanguage(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Education Level</label>
                            <input type="text" placeholder="value" value={educationLevel} onChange={(e)=>setEducationLevel(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Occupation(Before Retirement):</label>
                            <input type="text" placeholder="value" value={occupation} onChange={(e)=>setOccupation(e.target.value)}  />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Current Marital Status</label>
                            <input type="text" placeholder="value" value={maritalStatus} onChange={(e)=>setMaritalStatus(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-field">
                            <label for="">Spouse's Name</label>
                            <input type="text" placeholder="value" value={spouseName} onChange={(e)=>setSpouseName(e.target.value)} />
                        </div>
                    </div>
                   
                    <div className="col-md-12">
                        <div className="residential">
                            <h5>Residential Information</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-field-two">
                                        <label for="">Permanent Address</label>
                                        <input type="text" placeholder="Address" value={permanentAddress} onChange={parmanentAddress} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-field-two">
                                        <label for="">Current Address (If different)</label>
                                        <input type="text" placeholder="Address" value={currentAddress} onChange={(e)=>setCurrentAddress(e.target.value)} />

                                    </div>
                                    <div className="checkbox">
                                        <input className="form-check-input" value={sameAsParmanent} type="checkbox" onChange={sameParmanent}/>
                                        <label for="">Same as permanent</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                                                    <div className="contact-inforamtion">
                                                        <h5>Contact Information</h5>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Primary Phone Number</label>
                                                                    <input type="text" placeholder="value" value={contactPrimaryPhone} onChange={(e)=>setContactPrimaryPhone(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Secondary Phone Number</label>
                                                                    <input type="text" placeholder="value" value={contactSecondaryPhone} onChange={(e)=>setContactSecondaryPhone(e.target.value)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="input-field">
                                                                    <label for="">Email Address</label>
                                                                    <input type="text" placeholder="value" value={contactEmail} onChange={(e)=>setContactEmail(e.target.value)}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <p>Secondary Phone Number</p>
                                                                <div className="checkbox">
                                                                    <input className="form-check-input" type="checkbox" onChange={(e)=>setIsPhone(e.target.checked)}/>
                                                                    <label for="">Phone</label>
                                                                    <input className="form-check-input" type="checkbox" onChange={(e)=>setIsEmail(e.target.checked)}/>
                                                                    <label for="">Email</label>
                                                                    <input className="form-check-input" type="checkbox" onChange={(e)=>setIsText(e.target.checked)}/>
                                                                    <label for="">Text Message</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <SaveButton setLoading={setLoading} loading={loading}/>
                </div>
            </div>
           </form>
           <ToastContainer/>
        </>
    )
}