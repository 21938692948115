import React from "react";
import './Appointments.css'

export default function Appointments() {
  return (
    <>
      <div className="appointment-box">
        <div className="appointm-top d-flex justify-content-between align-items-center">
          <div>
            <h3>Upcoming Appointments</h3>
            <p>Your todays appointments</p>
          </div>
          <div>
            <a href="#">View All</a>
          </div>
        </div>
        <div className="appointment-info d-flex justify-content-between align-items-center">
          <div className="left-appoint d-flex justify-content-between align-items-center gap-1">
          <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture"/>
            <div className="appoint-name">
              <h4>Dr.John doe</h4>
              <p>New Patient</p>
            </div>
          </div>
          <div className="right-appoint d-flex justify-content-between align-items-center gap-3">
            <p>11:30 am</p>
            <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
          </div>
        </div>
        <div className="appointment-info d-flex justify-content-between align-items-center">
          <div className="left-appoint d-flex justify-content-between align-items-center gap-1">
          <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture"/>
            <div className="appoint-name">
              <h4>Dr.Marie</h4>
              <p>Weekly visit</p>
            </div>
          </div>
          <div className="right-appoint d-flex justify-content-between align-items-center gap-3">
            <p>12:00 am</p>
            <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
          </div>
        </div>
        <div className="appointment-info d-flex justify-content-between align-items-center">
          <div className="left-appoint d-flex justify-content-between align-items-center gap-1">
          <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture"/>
            <div className="appoint-name">
              <h4>Dr.John doe</h4>
              <p>Consultation</p>
            </div>
          </div>
          <div className="right-appoint d-flex justify-content-between align-items-center gap-3">
            <p>12:30 am</p>
            <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
          </div>
        </div>
        <div className="appointment-info d-flex justify-content-between align-items-center">
          <div className="left-appoint d-flex justify-content-between align-items-center gap-1">
          <img src="/images/Critical-Alerts-profile.svg" alt="Profile Picture"/>
            <div className="appoint-name">
              <h4>Dr.John doe</h4>
              <p>Weekly visit</p>
            </div>
          </div>
          <div className="right-appoint d-flex justify-content-between align-items-center gap-3">
            <p>12:50 am</p>
            <i className="fa-solid fa-ellipsis-stroke-vertical"></i>
          </div>
        </div>
      </div>
    </>
  );
}
